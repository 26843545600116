<template>
	<v-row>
		<v-col cols md="12">
			<h2
				class="text-h6 font-weight-bold text-center mt-2 mb-4 d-flex align-center justify-center">
				<v-img
					:lazy-src="`https://api-global.k0s.ime.red/uploads/vaim_c135f2de2b.png`"
					:src="`https://api-global.k0s.ime.red/uploads/vaim_c135f2de2b.png`"
					max-height="36"
					max-width="80"
					class="mr-4">
				</v-img>

				{{ geoData.name }}
			</h2>

			<!-- <pre>{{ geoData }}</pre> -->

			<v-simple-table class="my-1">
				<template v-slot:default>
					<tbody>
						<tr v-if="geoData.address">
							<td>
								<small class="text-uppercase blue-grey--text">
									Dirección
								</small>
								<p class="font-weight-bold">
									<a
										:href="`https://www.google.es/maps/place/${geoData.address}`"
										class="text-decoration-none"
										target="_blank">
										{{ geoData.address }}
									</a>
								</p>
							</td>
						</tr>
						<tr>
							<td>
								<small class="text-uppercase blue-grey--text">
									¿Qué es la VAIM?
								</small>
								<p class="font-weight-medium">
									Es una política pública que busca incorporar la perspectiva de
									género a las actividades efectuadas por las representaciones
									de México en el exterior. Sus objetivos son proteger a las
									mujeres víctimas de violencia conyugal y de pareja, así como
									promover el empoderamiento y la igualdad de género.
								</p>
							</td>
						</tr>
						<tr>
							<td>
								<small class="text-uppercase blue-grey--text">
									¿Qué servicios ofrece?
								</small>
								<p class="font-weight-medium">
									Brinda servicios y protección consular con perspectiva de
									género a mujeres y niñas, especialmente a víctimas de
									violencia o abuso sexual.
								</p>
							</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>

			<!-- <v-card max-height="550" class="elevation--4">
				<v-card-text>
					<v-simple-table class="my-1">
						<template v-slot:default>
							<tbody>
								<tr>
									<td>
										<small class="text-uppercase blue-grey--text">
											Servicios
										</small>
										<div
											class="html-block font-montserrat font-weight-medium"
											v-html="geoData.services"></div>
									</td>
								</tr>
								<tr>
									<td>
										<small class="text-uppercase blue-grey--text">
											Dirección
										</small>
										<p class="font-weight-medium text-capitalize">
											{{ geoData.address }}, CP. {{ geoData.postalCode }},
											{{ geoData.City }} {{ geoData.State }}
										</p>
									</td>
								</tr>
								<tr>
									<td>
										<small class="text-uppercase blue-grey--text">
											Representación
										</small>
										<p class="font-weight-medium text-capitalize">
											{{ geoData.rme.name }}
										</p>
									</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</v-card-text>
			</v-card> -->
		</v-col>
	</v-row>
</template>

<script>
export default {
	name: "VaimMap",

	props: {
		geoData: Object,
	},

	components: {},

	data: () => ({
		dialog: false,

		meta: {
			app_name: process.env.VUE_APP_NAME,
			app_version: process.env.VUE_APP_VERSION,
			base_url: process.env.VUE_APP_BASE_URL,
			endpoint: process.env.VUE_APP_ENDPOINT,
			storage_files: process.env.VUE_APP_STORAGE_FILES,
		},
	}),

	computed: {},

	mounted() {},

	methods: {
		cleanWin(winName) {
			let win = winName.replaceAll("Ventanilla de", "");
			return win;
		},
	},
};
</script>
