<template>
	<v-img
		:src="
			imageCover
				? meta.storage_files + imageCover
				: '/assets/images/back/rgmx7.jpg'
		"
		:lazy-src="
			imageCover
				? meta.storage_files + imageCover
				: '/assets/images/back/rgmx7.jpg'
		"
		:class="classes"
		:aspect-ratio="aspectRatio">
		<div
			class="fill-height gradient-black d-flex flex-column align-center text-center justify-center pa-4">
			<div>
				<h6>
					<a
						:href="`/clusters/${slug}`"
						class="text-h6 text-decoration-none white--text font-weight-medium text-shadow">
						<p>
							<v-icon class="display-4 white--text">{{ icon }}</v-icon>
						</p>

						{{ title }}
					</a>
				</h6>
			</div>
			<!-- <div class="mt-2">
				<v-chip
					class="ma-1"
					color="light-green darken-3"
					label
					dark
					:href="`/cluster/${slug}`">
					Leer más
					<v-icon right dark small class="">mdi-book-open-blank-variant</v-icon>
				</v-chip>
			</div> -->
		</div>
	</v-img>
</template>
<script>
//import wsApiRgmx from "@/services/api-rgmx";

export default {
	name: "ClusterCardContainer",

	props: {
		imageCover: {
			type: String,
			default: "/assets/images/back/rgmx7.jpg",
		},
		icon: {
			type: String,
			default: "mdi-atom",
		},
		title: {
			type: String,
			default: "Titulo",
		},
		slug: {
			type: String,
			default: "titulo",
		},
		classes: {
			type: String,
			default: "",
		},
		aspectRatio: {
			type: Number,
			default: 1.2,
		},
	},

	components: {},

	data: () => ({
		meta: {
			app_name: process.env.VUE_APP_NAME,
			base_url: process.env.VUE_APP_BASE_URL,
			endpoint: process.env.VUE_APP_ENDPOINT,
			storage_files: process.env.VUE_APP_STORAGE_FILES,
		},
	}),

	computed: {
		currentPage() {
			var result = this.$route.params.chapter;
			return result;
		},
		currentToday() {
			var f = new Date();
			var j = f.toJSON();
			//console.log("🚀 ~ currentToday ~ j:", j);
			return j;
		},
	},

	mounted() {},

	methods: {},
};
</script>
