var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('slither-slider',{attrs:{"options":{
			autoplay: true,
			transition: 'fade',
			dots: false,
			controls: false,
			fullscreen: false,
			animationDuration: _vm.multiplo,
		}}},_vm._l((_vm.slides),function(item,i){return _c('div',{key:i},[_c('v-img',{class:`${_vm.selectedColor === '256,256,256,.0' ? 'over-grey' : ''} `,attrs:{"src":_vm.meta.storage_files + _vm.addUrlToImage(item.url),"lazy-src":_vm.meta.storage_files + _vm.addUrlToImage(item.url),"aspect-ratio":"1","gradient":`to bottom, rgba(0,0,0,.25), rgba(${_vm.selectedColor})`,"id":`time-${_vm.multiplo}`}})],1)}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }