<template>
	<v-row class="">
		<v-col cols="auto">
			<v-dialog
				transition="dialog-bottom-transition"
				max-width="60%"
				:open-delay="50">
				<template v-slot:activator="{ on, attrs }">
					<v-btn dark fab class="rojo banner-ime" v-bind="attrs" v-on="on">
						<v-avatar size="40" class="corazon">
							<img src="/assets/favicons/apple-icon.png" alt="John" />
						</v-avatar>
					</v-btn>
				</template>
				<template v-slot:default="dialog">
					<v-card>
						<v-toolbar color="dorado-obscuro" dark> Anuncios IME </v-toolbar>
						<v-card-text class="pt-4">
							<!-- <div v-for="item in banners" class="pa-12">
								<pre>{{ item }}</pre>
							</div> -->

							<v-carousel show-arrows-on-hover hide-delimiters height="300">
								<v-carousel-item
									v-for="(item, i) in banners"
									:key="i"
									:src="meta.storage_files + item.imageBanner.url"
									reverse-transition="fade-transition"
									transition="fade-transition"></v-carousel-item>
							</v-carousel>
						</v-card-text>
						<v-card-actions class="justify-end">
							<v-btn
								fab
								dark
								color="green darken-4"
								text
								@click="dialog.value = false">
								<v-icon>mdi-close</v-icon>
							</v-btn>
						</v-card-actions>
					</v-card>
				</template>
			</v-dialog>
		</v-col>
	</v-row>
</template>

<script>
import wsApiIme from "@/services/api-ime";

export default {
	name: "HomeButtonDialogContainer",

	components: {},

	data: () => ({
		banners: {},
		model: 0,
		meta: {
			app_name: process.env.VUE_APP_NAME,
			base_url: process.env.VUE_APP_BASE_URL,
			endpoint: process.env.VUE_APP_ENDPOINT,
			storage_files: process.env.VUE_APP_STORAGE_FILES,
		},
	}),

	mounted() {
		//document.title = `Red Global MX | ${this.meta.app_name}`;
		this.loadBanners();
	},

	methods: {
		loadBanners() {
			wsApiIme.getBanners().then(
				(response) => {
					this.banners = response.data.data.itemBanner;
				},
				(error) => {
					this.banners =
						(error.response && error.response.data) ||
						error.message ||
						error.toString();
				}
			);
		},
	},
};
</script>
<style>
.banner-ime {
	position: fixed;
	z-index: 999;
	bottom: 40px;
	right: 40px;
}
</style>
