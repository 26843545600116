import axios from "axios";
export default axios.create({
  baseURL: process.env.VUE_APP_ENDPOINT,
  //crossdomain: true,
  //timeout: 1000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json;charset=utf-8",
    //"Access-Control-Allow-Origin": "*",
  },
});
