import http from "@/services/http-common";
import authHeader from "@/services/auth-header";

class wsApiRgmx {
	/* Homepage */

	/* RGMX */
	getAllArticles() {
		return http.get(`/articles/`, { headers: authHeader() });
	}
	getMenuRmgx() {
		return http.get(`/pages/?filters[section][$eq]=rgmx`, {
			headers: authHeader(),
		});
	}

	/* Regiones */
	getRegion(region) {
		return http.get(
			`/regions/?filters[title][$eq]=${region}&populate[imageHeader][fields][1]=url&populate[imageCover][fields][1]=url&populate[social_media][fields][2]=*&populate[contacto][fields][3]=*&populate[location][fields][4]=*&populate[boardDirectors][fields][5]=*`,
			{
				headers: authHeader(),
			}
		);
	}
	getRegionWithChapters(region) {
		return http.get(
			`/regions/?filters[title][$eq]=${region}&populate[chapters][fields][1]=*&populate[imageCover][fields][1]=url&populate[social_media][fields][2]=*&populate[contacto][fields][3]=*&populate[location][fields][4]=*`,
			{
				headers: authHeader(),
			}
		);
	}
	getAllRegions() {
		return http.get(
			`/regions/?sort=title`, // ?populate[chapters][fields][0]=title&populate[chapters][fields][1]=slug
			{
				headers: authHeader(),
			}
		);
	}
	getRegionsDirectory() {
		return http.get(
			`/regions/?sort=title&populate[chapters][populate][0]=contacto&populate[imageCover][fields][1]=url`,
			{
				headers: authHeader(),
			}
		);
	}

	/* Pilares */
	getClusterByTemathic(cluster) {
		return http.get(
			`/thematic-clusters/?filters[slug][$eq]=${cluster}&populate[capitulos][populate][0]=imageHeader`,
			{
				headers: authHeader(),
			}
		);
	}

	/* Clusters */
	getClusters() {
		return http.get(`/pillars/?populate=*`, {
			headers: authHeader(),
		});
	}
	getClusterByCluster(cluster) {
		return http.get(`/pillars/?filters[slug][$eq]=${cluster}&populate=*`, {
			headers: authHeader(),
		});
	}

	/* Capitulos */
	getAllChapters() {
		return http.get(`/chapters/?populate=*`, {
			headers: authHeader(),
		});
	}
	getChaptersByRegion(region) {
		return http.get(
			`/chapters/?filters[region][title][$eq]=${region}&populate[imageHeader][fields][1]=url&populate[imageCover][fields][1]=url&populate[location][fields][2]=*&sort=title`,
			{
				headers: authHeader(),
			}
		);
	}
	allChaptersByRegion(region) {
		return http.get(`/chapters/?filters[region][title][$eq]=${region}`, {
			headers: authHeader(),
		});
	}
	getAllChaptersById(id) {
		return http.get(`/chapters/?filters[region][id][$eq]=${id}`, {
			headers: authHeader(),
		});
	}
	getChapter(chapter) {
		return http.get(`/chapters/?filters[slug][$eq]=${chapter}&populate=*`, {
			headers: authHeader(),
		});
	}
	getChaptersMap() {
		return http.get(
			`/chapters/?fields[0]=title&fields[1]=slug&fields[2]=summary&populate[geolocation][fields][3]=*&populate[region][fields][4]=name&populate[region][fields][5]=colorPin&populate[contacto][fields][2]=*`, //
			{
				headers: authHeader(),
			}
		);
	}
	getChaptersDirectory() {
		return http.get(`/chapters/?populate=*`, {
			headers: authHeader(),
		});
	}

	/* Nodos */
	getAllNodes() {
		return http.get(`/nodes/?populate[imageHeader][fields][0]=url&sort=title`, {
			headers: authHeader(),
		});
	}
	getNode(node) {
		return http.get(`/nodes/?filters[slug][$eq]=${node}&populate=*`, {
			headers: authHeader(),
		});
	}
	getNodesMap() {
		return http.get(
			`/nodes/?fields[0]=title&fields[1]=slug&fields[2]=summary&populate[geolocation][fields][3]=*&populate[Contacto][fields][4]=*`,
			{
				headers: authHeader(),
			}
		);
	}
	getNodesDirectory() {
		return http.get(
			`/nodes/?populate[Contacto][fields][0]=*`, //populate[Contacto][populate][0]=*
			{
				headers: authHeader(),
			}
		);
	}

	/* Proyectos */
	getAllProjects(pageNumber, pageSize) {
		return http.get(
			`/projects/?sort=updatedAt:desc&populate[imageCover][fields][0]=url&populate[chapter][fields][1]=title&populate[chapter][fields][2]=slug&fields[0]=title&fields[1]=slug&pagination[page]=${
				pageNumber ? pageNumber : 1
			}&pagination[pageSize]=${pageSize ? pageSize : 9}`,
			{
				headers: authHeader(),
			}
		);
	}
	getAllProjectsFavorites(paginationLimit) {
		return http.get(
			`/projects/?filters[leading][$eq]=true&populate[imageCover][fields][0]=url&populate[author][fields][1]=fullName&populate[chapter][fields][0]=*&pagination[limit]=${paginationLimit}`, //
			{
				headers: authHeader(),
			}
		);
	}
	getProjectsFavorites() {
		return http.get(`/projects/?filters[favorite][$eq]=favorite&populate=*`, {
			headers: authHeader(),
		});
	}
	getProject(project) {
		return http.get(`/projects/?filters[slug][$eq]=${project}&populate=*`, {
			headers: authHeader(),
		});
	}

	/* Mexico en directo */
	getMxDirect() {
		return http.get(`/mxdirect/`, { headers: authHeader() });
	}

	/* Mexicanos talentosos */
	getTalentedMexicans(items) {
		return http.get(
			`/talented-mexicans/?populate[imageCover][fields][1]=url&pagination[limit]=${
				items ? items : 3
			}&sort[0]=updatedAt:desc`,
			{
				headers: authHeader(),
			}
		);
	}

	/* paginas */
	getPages(page) {
		return http.get(
			`/pages/?populate[author][fields][0]=fullName&populate[imageHeader][fields][1]=url&populate[imageCover][fields][1]=url&filters[slug][$eq]=${page}&populate[region][fields][0]=*`,
			{
				headers: authHeader(),
			}
		);
	}

	getPagesBySection(section) {
		return http.get(
			`/pages/?populate[author][fields][0]=fullName&populate[imageHeader][fields][1]=url&populate[imageCover][fields][1]=url&filters[section][$eq]=${section}`,
			{
				headers: authHeader(),
			}
		);
	}

	/* Eventos */
	getAllEvents(pageNumber, pageSize) {
		return http.get(
			`/events/?sort[0]=start:asc&populate[imageCover][fields][0]=url&populate[author][fields][1]=fullName&populate[chapter][fields][2]=title&populate[chapter][fields][3]=slug&populate[node][fields][4]=title&populate[node][fields][5]=slug&pagination[page]=${
				pageNumber ? pageNumber : 1
			}&pagination[pageSize]=${pageSize ? pageSize : 9}`,
			{
				headers: authHeader(),
			}
		);
	}

	getUpcomingEvents(dateNow, pageOrder, pageSize) {
		return http.get(
			`/events/?filters[start][$gte]=${dateNow}&sort[0]=start:${pageOrder}&fields[1]=name&fields[2]=slug&fields[3]=start&populate[imageCover][fields][4]=url&populate[author][fields][5]=fullName&populate[chapter][fields][6]=title&populate[chapter][fields][7]=slug&populate[node][fields][8]=title&populate[node][fields][9]=slug&pagination[page]=1&pagination[pageSize]=${pageSize}`,
			{
				headers: authHeader(),
			}
		);
	}

	getEventsMap(dateNow) {
		return http.get(`/events/?filters[start][$gte]=${dateNow}`, {
			headers: authHeader(),
		});
	}

	getAllEventsB() {
		return http.get(`/events/`, {
			headers: authHeader(),
		});
	}

	getEvent(event) {
		return http.get(`/events/?filters[slug][$eq]=${event}&populate=*`, {
			headers: authHeader(),
		});
	}
}

export default new wsApiRgmx();
