<template>
  <v-footer dark padless class="">
    <v-row class="light-green darken-3 white--text">
      <v-col cols md="4" class="d-flex justify-end">
        <v-img
          alt="Red Global MX"
          class="logo shrink animate__animated animate__fadeInDown animate__faster"
          width="65%"
          contain
          lazy-src="/assets/images/logos/rgmx-logo.png"
          src="/assets/images/logos/rgmx-logo.png"
        />
      </v-col>
      <v-col cols md="4" class="text-center pa-6">
        <p>
          La Red Global Mx reúne a individuos interesados en la promoción del
          desarrollo de México y en particular a través de la inserción de
          México en la economía del conocimiento.
        </p>
      </v-col>
      <v-col cols md="4" class="d-flex align-center">
        <h4 class="block">Siguenos</h4>

        <v-btn v-for="icon in icons" :key="icon" class="mx-4 white--text" icon>
          <v-icon size="24px">
            {{ icon }}
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="light-green darken-4 white--text text-center">
      <v-col cols md="12">
        <v-img
          alt="Red Global MX"
          class="shrink animate__animated animate__fadeInDown animate__faster"
          height="50"
          contain
          lazy-src="/assets/images/logos/gobmx.svg"
          src="/assets/images/logos/gobmx.svg"
        />
      </v-col>
    </v-row>

    <!--v-row class="pink darken-3">
      <v-col cols md="12" class="white--text text-center">
        {{ new Date().getFullYear() }} — Secretaría de Relaciones Exteriores,
        <strong>Instituto de los Mexicanos en el Exterior</strong>
      </v-col>
    </v-row-->
  </v-footer>
</template>
<script>
export default {
  name: "FooterTemplate",

  data: () => ({
    icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
  }),
};
</script>
