<template>
  <v-container>
    <v-row>
      <v-col>
        <h2
          class="display-2 blue-grey--text text--darken-4 my-4 text-uppercase text-center"
        >
          <strong>¿Cómo se conforma</strong> la RGMX?
        </h2>
      </v-col>
    </v-row>
    <v-row class="mb-15 d-flex align-center">
      <v-col cols md="5" class="px-16">
        <v-img
          :src="meta.storage_files + getChapter.imageCover.url"
          :lazy-src="meta.storage_files + getChapter.imageCover.url"
          class=""
          :aspect-ratio="1.7"
        />
      </v-col>
      <v-col cols md="5" class="px-16">
        <h3 class="display-1 text-center text-uppercase strong mb-4">
          {{ getChapter.title }}
        </h3>
        <p>{{ getChapter.description }}</p>
      </v-col>
      <v-col>
        <v-btn
          :href="`${getChapter.url}`"
          x-large
          tile
          dark
          color="pink darken-2 elevation-0"
          >Más Info</v-btn
        >
      </v-col>
    </v-row>

    <v-row class="mb-15 d-flex align-center">
      <v-col>
        <v-btn
          :href="`${getNode.url}`"
          x-large
          tile
          dark
          color="yellow darken-3 float-right elevation-0"
          >Más Info</v-btn
        >
      </v-col>
      <v-col cols md="5" class="px-16">
        <h3 class="display-1 text-center text-uppercase strong mb-4">
          {{ getNode.title }}
        </h3>
        <p>{{ getNode.description }}</p>
      </v-col>
      <v-col cols md="5" class="px-16">
        <v-img
          :src="meta.storage_files + getNode.imageCover.url"
          :lazy-src="meta.storage_files + getNode.imageCover.url"
          class=""
          :aspect-ratio="1.7"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import wsApiHome from "@/services/api-home";

export default {
  name: "AboutRgmxComponent",

  components: {},

  data: () => ({
    getNode: {
      imageCover: {},
    },
    getChapter: {
      imageCover: {},
    },

    meta: {
      app_name: process.env.VUE_APP_NAME,
      base_url: process.env.VUE_APP_BASE_URL,
      endpoint: process.env.VUE_APP_ENDPOINT,
      storage_files: process.env.VUE_APP_STORAGE_FILES,
    },
  }),

  created() {},

  mounted() {
    this.loadHomePage();
  },

  methods: {
    loadHomePage() {
      wsApiHome.getHomePageTotal().then(
        (response) => {
          this.currentHomePage = response.data.data;
          this.getNode = this.currentHomePage.callAction[0];
          this.getChapter = this.currentHomePage.callAction[1];
        },
        (error) => {
          this.currentHomePage =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },

    addUrlToImage(htmlCode) {
      let url = htmlCode.replaceAll("/uploads/", "thumbnail_");
      return url;
    },
  },
};
</script>
