<template>
	<section>
		<CollageComponent
			:title="currentHomePage.home_map.title"
			:subtitle="currentHomePage.home_map.subtitle"
			:slogan="currentHomePage.home_map.slogan"
			:content="currentHomePage.home_map.content"></CollageComponent>

		<!-- <v-row>
			<v-col class="space my-10">&nbsp;</v-col>
		</v-row> -->

		<EventsComponent></EventsComponent>

		<!-- <v-row>
			<v-col class="space my-10">&nbsp;</v-col>
		</v-row> -->

		<!-- <MapBoxLayout></MapBoxLayout> -->
		<MapContainer></MapContainer>

		<v-row>
			<v-col class="space my-10">&nbsp;</v-col>
		</v-row>

		<AboutRgmxComponent></AboutRgmxComponent>

		<v-row>
			<v-col class="space my-10">&nbsp;</v-col>
		</v-row>

		<div class="gradient-yellow">
			<div
				class="projects yellow"
				:style="`
					background-image: url('${
						meta.storage_files + currentHomePage.projectsConfig.background.url
					}');
					background-position: top center%;
					background-repeat: no-repeat;
					background-size: cover;

					opacity: 0.99;
					`">
				<v-container>
					<v-row dark class="pa-6">
						<!-- parallax-gradiente rounded-xl -->
						<v-col cols md="12" class="">
							<h2 class="display-2 text-uppercase text-center mb-4">
								<span class="block orange lighten-1 px-10 py-2">
									<span v-html="currentHomePage.projectsConfig.title" />
								</span>
							</h2>
						</v-col>
						<v-col>
							<carousel
								cycle
								:autoplay="true"
								:perPageCustom="[
									[320, 1],
									[480, 2],
									[768, 3],
									//[1199, 4],
								]"
								:centerMode="true"
								:loop="true"
								:speed="currentHomePage.projectsConfig.carouselSpeed"
								:autoplayTimeout="4000"
								:interval="3000">
								<slide
									v-for="(project, i) in projects"
									:key="i"
									:data-index="project.id"
									:data-name="project.slug">
									<div class="ma-4 text-center white--text">
										<v-chip
											v-if="project.chapter"
											color="white text--uppercase"
											class="font-weight-regular px-8 py-2">
											{{ project.chapter.title }}
										</v-chip>
										<img
											v-if="project.imageCover"
											:src="meta.storage_files + project.imageCover.url"
											:lazy-src="meta.storage_files + project.imageCover.url"
											class="rounded-xl elevation-5 mx-auto mt-2 over-grey"
											style="width: 420px; max-width: 100%" />
										<img
											v-else
											:src="`${meta.storage_files}/uploads/RGMX_Proyectos_ce564a2e07.jpg`"
											lazy-src="/assets/images/back/background5.jpg"
											class="rounded-xl elevation-5 mx-auto mt-n6"
											style="width: 420px; max-width: 100%" />

										<a
											:href="`/proyectos/${project.slug}`"
											class="text-decoration-none">
											<p
												class="lead strong white--text text-shadow pink d-flex align-center px-3"
												style="min-height: 100px; max-height: 120px">
												{{ project.title }}
											</p>
										</a>
									</div>
								</slide>
							</carousel>
							<small class="float-right font-italic white--text">
								<span v-html="currentHomePage.projectsConfig.copyright"></span>
							</small>
						</v-col>
					</v-row>
				</v-container>
			</div>
		</div>

		<MexicanTalentedComponent
			:info-title="currentHomePage.talentedMexicansConfig.title" />

		<!-- <v-container class="mb-16 mt-12 pa-10">
			<div class="talented-mexicans">
				<v-row dark class="pa-6">
					<v-col cols md="12">
						<h2 class="display-2 text-uppercase text-center mb-4">
							<span
								v-html="currentHomePage.talentedMexicansConfig.title"></span>
						</h2>
					</v-col>
					<v-col
						cols
						md="3"
						v-for="(item, i) in talentedMexicans"
						:key="i"
						class="text-center">
						<v-avatar size="250" class="gradient---yellow">
							<img
								v-if="item.imageCover"
								:src="meta.storage_files + item.imageCover.url"
								:lazy-src="meta.storage_files + item.imageCover.url"
								class="mb-2"
								:aspect-ratio="1.9" />
							<v-img
								v-else
								:src="` https://i.ytimg.com/vi/${item.youtube_id}/hqdefault.jpg`"
								:lazy-src="`https://i.ytimg.com/vi/${item.youtube_id}/hqdefault.jpg`"
								class="mb-2"
								:aspect-ratio="1.1" />
						</v-avatar>
						<h3 class="text-h5 text-center">
							<a
								:href="`https://www.youtube.com/watch?v=${item.youtube_id}`"
								target="_blank"
								class="black--text text-decoration-none">
								{{ item.title }}
							</a>
						</h3>
						<p>Cargo</p>
					</v-col>
				</v-row>
			</div>
		</v-container> -->

		<div class="amber darken-1">
			<v-container class="mb-16 mt-12 pa-10">
				<v-col cols md="12">
					<h2
						class="display-2 text-uppercase text-center blue-grey--text text--darken-4 mb-4">
						<strong>Pilares</strong> de la RGMX
					</h2>
				</v-col>
				<v-row class="pa--5 mb-15">
					<v-col cols md="6" class="px-8">
						<v-row>
							<v-col cols md="3">
								<v-btn
									dark
									tile
									block
									x-large
									color="pink darken-1"
									class="text-capitalize elevation-0"
									:to="getPillar.a.url ? getPillar.a.url : '#'">
									Más info
								</v-btn>
							</v-col>
							<v-col cols md="9">
								<v-img
									:src="meta.storage_files + getPillar.a.imageCover.url"
									:lazy-src="meta.storage_files + getPillar.a.imageCover.url"
									class="rounded--xl elevation--5"
									aspect-ratio="1.4" />
								<h3
									class="text-h5 text-center strong blue-grey--text text--darken-4 my-4">
									<a
										:href="getPillar.d.url ? getPillar.a.url : '#'"
										class="blue-grey--text text--darken-4">
										{{ getPillar.a.title }}
									</a>
								</h3>
							</v-col>
						</v-row>
					</v-col>
					<v-col cols md="6" class="px-8">
						<v-row>
							<v-col cols md="9">
								<v-img
									:src="meta.storage_files + getPillar.b.imageCover.url"
									:lazy-src="meta.storage_files + getPillar.b.imageCover.url"
									class="rounded--xl elevation--5"
									aspect-ratio="1.4" />
								<h3
									class="text-h5 text-center strong blue-grey--text text--darken-4 my-4">
									<a
										:href="getPillar.b.url ? getPillar.b.url : '#'"
										class="blue-grey--text text--darken-4">
										{{ getPillar.b.title }}
									</a>
								</h3>
							</v-col>
							<v-col cols md="3">
								<v-btn
									dark
									tile
									block
									x-large
									color="light-green darken-2"
									class="text-capitalize elevation-0"
									:to="getPillar.b.url ? getPillar.b.url : '#'">
									Más info
								</v-btn>
							</v-col>
						</v-row>
					</v-col>
				</v-row>

				<v-row class="">
					<v-col cols md="6" class="px-8">
						<v-row>
							<v-col cols md="3">
								<v-btn
									dark
									tile
									block
									x-large
									color="orange darken-4 elevation-0"
									class="text-capitalize"
									:to="getPillar.c.url ? getPillar.c.url : '#'">
									Más info
								</v-btn>
							</v-col>
							<v-col cols md="9">
								<v-img
									:src="meta.storage_files + getPillar.c.imageCover.url"
									:lazy-src="meta.storage_files + getPillar.c.imageCover.url"
									class="rounded--xl elevation--5"
									aspect-ratio="1.4" />
								<h3
									class="text-h5 text-center strong blue-grey--text text--darken-4 my-4">
									<a
										:href="getPillar.d.url ? getPillar.c.url : '#'"
										class="blue-grey--text text--darken-4">
										{{ getPillar.c.title }}
									</a>
								</h3>
							</v-col>
						</v-row>
					</v-col>

					<v-col cols md="6" class="px-8">
						<v-row>
							<v-col cols md="9">
								<v-img
									:src="meta.storage_files + getPillar.d.imageCover.url"
									:lazy-src="meta.storage_files + getPillar.d.imageCover.url"
									class="rounded--xl elevation--5"
									aspect-ratio="1.4" />
								<h3 class="text-h5 text-center strong my-4">
									<a
										:href="getPillar.d.url ? getPillar.d.url : '#'"
										class="blue-grey--text text--darken-4">
										{{ getPillar.d.title }}
									</a>
								</h3>
							</v-col>
							<v-col cols md="3">
								<v-btn
									dark
									tile
									block
									x-large
									color="#02849B"
									class="text-capitalize elevation-0"
									:to="getPillar.d.url ? getPillar.d.url : '#'">
									Más info
								</v-btn>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-container>
		</div>

		<ProgramsComponent></ProgramsComponent>

		<ClustersComponent></ClustersComponent>
	</section>
</template>

<script>
import wsApiRgmx from "@/services/api-rgmx";
import wsApiHome from "@/services/api-home";
// import MapBoxLayout from "@/components/layout/MapBox.vue";
import MapContainer from "@/containers/geo-ime/Map.vue";
import EventsComponent from "@/components/HomePage/Events.vue";
import ClustersComponent from "@/components/HomePage/Clusters.vue";
import ProgramsComponent from "@/components/HomePage/Programs.vue";
import CollageComponent from "@/components/HomePage/Collage.vue";
import AboutRgmxComponent from "@/components/HomePage/AboutRgmx.vue";
import MexicanTalentedComponent from "@/components/HomePage/MexicansTalented.vue";

export default {
	name: "HomeVersionOneComponent",

	components: {
		//MapBoxLayout,
		EventsComponent,
		ClustersComponent,
		ProgramsComponent,
		CollageComponent,
		AboutRgmxComponent,
		MapContainer,
		MexicanTalentedComponent,
	},

	data: () => ({
		currentHomePage: {
			projectsConfig: {
				background: {},
			},
			talentedMexicansConfig: {
				background: {},
			},
			home_map: {},
		},
		getNode: {},
		getChapter: {},
		getPillar: {
			a: { imageCover: {} },
			b: { imageCover: {} },
			c: { imageCover: {} },
			d: { imageCover: {} },
		},

		currentCollage: {},

		talentedMexicans: {},
		projects: {
			imageCover: {},
			chapter: {},
		},
		cycle: true,
		show: false,
		slide: 0,

		colors: ["yellow", "green", "pink", "blue"],
		//colors: ["255,160,0", "104,159,56", "216,27,96", "2,132,155"],
		selectedColor: null,

		currentImg: 0,

		meta: {
			app_name: process.env.VUE_APP_NAME,
			base_url: process.env.VUE_APP_BASE_URL,
			endpoint: process.env.VUE_APP_ENDPOINT,
			storage_files: process.env.VUE_APP_STORAGE_FILES,
		},
	}),

	created() {
		const idx = Math.floor(Math.random() * this.colors.length);
		this.selectedColor = this.colors[idx];
	},

	mounted() {
		//document.title = "Este es mi México | Vinculación y Cultura | IME";
		this.loadCollage();
		this.loadHomePage();
		this.loadAllProjects(12);
		this.loadTalentedMexicans();

		setInterval(() => {
			this.currentImg = this.currentImg + 1;
		}, 3000);
	},

	methods: {
		loadHomePage() {
			wsApiHome.getHomePageTotal().then(
				(response) => {
					this.currentHomePage = response.data.data;
					this.getNode = this.currentHomePage.callAction[0];
					this.getChapter = this.currentHomePage.callAction[1];
					this.getPillar.a = this.currentHomePage.pillars[0];
					this.getPillar.b = this.currentHomePage.pillars[1];
					this.getPillar.c = this.currentHomePage.pillars[2];
					this.getPillar.d = this.currentHomePage.pillars[3];
				},
				(error) => {
					this.currentHomePage =
						(error.response && error.response.data) ||
						error.message ||
						error.toString();
				}
			);
		},

		loadCollage() {
			wsApiHome.getCollage().then(
				(response) => {
					this.currentCollage = response.data.data.image;
				},
				(error) => {
					this.currentCollage =
						(error.response && error.response.data) ||
						error.message ||
						error.toString();
				}
			);
		},

		loadTalentedMexicans() {
			wsApiRgmx.getTalentedMexicans().then(
				(response) => {
					this.talentedMexicans = response.data.data;
				},
				(error) => {
					this.talentedMexicans =
						(error.response && error.response.data) ||
						error.message ||
						error.toString();
				}
			);
		},

		loadAllProjects(paginationLimit) {
			wsApiRgmx.getAllProjectsFavorites(paginationLimit).then(
				(response) => {
					this.projects = response.data.data;
				},
				(error) => {
					this.projects =
						(error.response && error.response.data) ||
						error.message ||
						error.toString();
				}
			);
		},

		loadThumbnail(url) {
			let thumb = url.replaceAll("/uploads/", "/uploads/thumbnail_");
			return thumb;
		},

		addUrlToImage(htmlCode) {
			let url = htmlCode.replaceAll("/uploads/", "thumbnail_");
			return url;
		},

		handleSlideClick(dataset) {
			//@slideclick="handleSlideClick
			console.log(
				"🚀 ~ file: HelloWorld.vue ~ handleSlideClick ~ dataset:",
				dataset
			);
			//console.log(dataset.index, dataset.name);
		},
	},
};
</script>
