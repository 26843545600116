<template>
	<section>
		<v-container class="mb-16 mt-12 pa-10">
			<div class="talented-mexicans">
				<v-row dark class="pa-6">
					<v-col cols md="12">
						<h2 class="display-2 text-uppercase text-center mb-4">
							<span v-html="infoTitle"></span>
						</h2>
					</v-col>
					<v-col
						cols
						md="4"
						v-for="(item, i) in talentedMexicans"
						:key="i"
						class="text-center mb-1">
						<TalentCardContainer
							:image-cover="`https://i.ytimg.com/vi/${item.youtube_id}/sddefault.jpg`"
							:title="item.title"
							:slug="item.youtube_id"
							:job="item.job"
							:aspect-ratio="1.8" />
					</v-col>
				</v-row>
			</div>
		</v-container>
	</section>
</template>

<script>
import wsApiRgmx from "@/services/api-rgmx";
import TalentCardContainer from "@/containers/cards/talent-card.vue";

export default {
	name: "MexicanTalentedComponent",

	components: {
		TalentCardContainer,
	},

	props: {
		infoTitle: {
			type: String,
		},
	},

	data: () => ({
		talentedMexicans: {},

		cycle: true,
		show: false,
		slide: 0,

		currentImg: 0,

		meta: {
			app_name: process.env.VUE_APP_NAME,
			base_url: process.env.VUE_APP_BASE_URL,
			endpoint: process.env.VUE_APP_ENDPOINT,
			storage_files: process.env.VUE_APP_STORAGE_FILES,
		},
	}),

	created() {},

	mounted() {
		//document.title = "Este es mi México | Vinculación y Cultura | IME";
		this.loadTalentedMexicans(6);
	},

	methods: {
		loadTalentedMexicans(items) {
			wsApiRgmx.getTalentedMexicans(items).then(
				(response) => {
					this.talentedMexicans = response.data.data;
				},
				(error) => {
					this.talentedMexicans =
						(error.response && error.response.data) ||
						error.message ||
						error.toString();
				}
			);
		},
	},
};
</script>
