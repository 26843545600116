<template>
	<v-row>
		<v-col cols md="12">
			<h2
				class="text-h6 font-weight-bold text-center mt-2 mb-4 d-flex align-center justify-center">
				<v-img
					:lazy-src="`https://api-global.k0s.ime.red/uploads/plazas_comunitarias_95ca274a91.jpg`"
					:src="`https://api-global.k0s.ime.red/uploads/plazas_comunitarias_95ca274a91.jpg`"
					max-height="32"
					max-width="72"
					class="mr-4">
				</v-img>

				{{ geoData.name }}
			</h2>

			<v-card max-height="550" class="elevation--4">
				<v-card-text>
					<v-simple-table class="my-1">
						<template v-slot:default>
							<tbody>
								<tr>
									<td>
										<small class="text-uppercase blue-grey--text">
											Servicios
										</small>
										<div
											class="html-block font-montserrat font-weight-medium"
											v-html="geoData.services"></div>
									</td>
								</tr>
								<tr>
									<td>
										<small class="text-uppercase blue-grey--text">
											Dirección
										</small>
										<p class="font-weight-medium text-capitalize">
											{{ geoData.address }}, CP. {{ geoData.postalCode }},
											{{ geoData.City }} {{ geoData.State }}
										</p>
									</td>
								</tr>
								<tr>
									<td>
										<small class="text-uppercase blue-grey--text">
											Representación
										</small>
										<p class="font-weight-medium text-capitalize">
											{{ geoData.rme.name }}
										</p>
									</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</v-card-text>

				<v-divider></v-divider>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
export default {
	name: "PlazaComunitariaMap",

	props: {
		geoData: Object,
	},

	components: {},

	data: () => ({
		dialog: false,
		dialogComunidades: false,

		meta: {
			app_name: process.env.VUE_APP_NAME,
			app_version: process.env.VUE_APP_VERSION,
			base_url: process.env.VUE_APP_BASE_URL,
			endpoint: process.env.VUE_APP_ENDPOINT,
			storage_files: process.env.VUE_APP_STORAGE_FILES,
		},
	}),

	computed: {},

	mounted() {},

	methods: {
		moreInformation(rmeName, agency) {
			this.dialog = true;
			this.rme_name = rmeName;
			this.data_agency = agency;
		},

		consulInformation(rmeName, comunidades) {
			this.dialogComunidades = true;
			this.rme_name = rmeName;
			this.data_consul = comunidades;
		},

		cleanWin(winName) {
			let win = winName.replaceAll("Ventanilla de", "");
			return win;
		},
	},
};
</script>
