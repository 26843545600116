<template>
	<v-simple-table class="my-4">
		<template v-slot:default>
			<tbody>
				<tr>
					<td>
						<small class="text-uppercase blue-grey--text">
							Horario de atención
						</small>
						<p class="font-weight-medium">
							{{ dataAgency.hourly }}
						</p>
					</td>
				</tr>
				<tr>
					<td>
						<small class="text-uppercase blue-grey--text"> Servicios </small>
						<div
							class="html-block font-weight-medium"
							v-html="dataAgency.services"></div>
					</td>
				</tr>
				<tr>
					<td>
						<small class="text-uppercase blue-grey--text">
							Organización o Institución Aliada
						</small>
						<p class="font-weight-medium text-capitalize">
							{{ dataAgency.allies.toLowerCase() }}
						</p>
					</td>
				</tr>

				<tr class="blue-grey darken-2">
					<td class="white--text text-center text-uppercase strong">
						Operador de la ventanilla
					</td>
				</tr>
				<tr>
					<td>
						<small class="text-uppercase blue-grey--text"> Operador </small>
						<p class="font-weight-medium">
							{{ dataAgency.operatorName }}
						</p>
					</td>
				</tr>
				<tr>
					<td>
						<small class="text-uppercase blue-grey--text"> Teléfono/Ext </small>
						<p class="font-weight-medium">
							{{ dataAgency.operatorPhone }}
						</p>
					</td>
				</tr>
				<tr>
					<td>
						<small class="text-uppercase blue-grey--text">
							Correo electrónico
						</small>
						<p class="font-weight-medium">
							{{ dataAgency.operatorEmail }}
						</p>
					</td>
				</tr>

				<tr v-if="dataAgency.maex" class="blue-grey darken-2">
					<td class="white--text text-center text-uppercase strong">MAEX</td>
				</tr>
				<tr v-if="dataAgency.maex">
					<td>
						<small class="text-uppercase blue-grey--text">
							Horario de Atención
						</small>
						<p class="font-weight-medium">
							{{ dataAgency.maexHourly }}
						</p>
					</td>
				</tr>
				<tr v-if="dataAgency.maex">
					<td>
						<small class="text-uppercase blue-grey--text">
							Correo electrónico
						</small>
						<p class="font-weight-medium">
							{{ dataAgency.maexEmail }}
						</p>
					</td>
				</tr>
			</tbody>
		</template>
	</v-simple-table>
</template>

<script>
export default {
	name: "RmePopupTable",

	props: {
		dataAgency: Object,
	},

	components: {},

	data: () => ({
		dialog: false,
		dialogComunidades: false,

		meta: {
			app_name: process.env.VUE_APP_NAME,
			app_version: process.env.VUE_APP_VERSION,
			base_url: process.env.VUE_APP_BASE_URL,
			endpoint: process.env.VUE_APP_ENDPOINT,
			storage_files: process.env.VUE_APP_STORAGE_FILES,
		},
	}),

	computed: {},

	mounted() {},

	methods: {},
};
</script>
