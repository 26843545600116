<template>
	<v-row>
		<v-col cols md="12" class="text-center">
			<v-img
				:lazy-src="`https://api-rgmx.k0s.ime.red/uploads/rgmx_be24cbb47d.png`"
				:src="`https://api-rgmx.k0s.ime.red/uploads/rgmx_be24cbb47d.png`"
				max-height="100"
				max-width="250"
				class="mx-auto">
			</v-img>
			<h2
				class="text-h6 font-weight-bold text-center mt-2 mb-4 d-flex align-center justify-center">
				{{ geoData.name }}
			</h2>

			<v-card max-height="550" class="elevation--4">
				<v-card-text>
					<v-simple-table class="my-1">
						<template v-slot:default>
							<tbody>
								<tr>
									<td colspan="2">
										<small class="text-uppercase blue-grey--text">
											Fechas
										</small>
										<p class="font-weight-medium text-capitalize">
											{{ geoData.start }}
											<span v-if="geoData.end"> al {{ geoData.end }}</span>
										</p>
									</td>
								</tr>
								<tr>
									<td colspan="2">
										<small class="text-uppercase blue-grey--text">
											Descripción
										</small>
										<div
											class="html-block font-montserrat font-weight-medium"
											v-html="geoData.details"></div>
									</td>
								</tr>
								<tr>
									<td width="50%" class="text-center">
										<img
											:src="
												geoData.imageCover
													? meta.storage_files + geoData.imageCover.url
													: '/assets/images/back/rgmx1.jpg'
											"
											:lazy-src="
												geoData.imageCover
													? meta.storage_files + geoData.imageCover.url
													: '/assets/images/back/rgmx1.jpg'
											"
											class="elevation-4 my-4 responsive" />
									</td>
									<td width="50%" class="text-center">
										<v-btn
											dark
											color="rojo"
											:href="`/evento/${geoData.slug}`"
											class="my-4">
											Más Info
										</v-btn>
									</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</v-card-text>

				<v-divider></v-divider>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
export default {
	name: "EventsRmgxMap",

	props: {
		geoData: Object,
	},

	components: {},

	data: () => ({
		dialog: false,
		dialogComunidades: false,

		meta: {
			app_name: process.env.VUE_APP_NAME,
			app_version: process.env.VUE_APP_VERSION,
			base_url: process.env.VUE_APP_BASE_URL,
			endpoint: process.env.VUE_APP_ENDPOINT,
			storage_files: process.env.VUE_APP_STORAGE_FILES,
		},
	}),

	computed: {},

	mounted() {},

	methods: {
		moreInformation(rmeName, agency) {
			this.dialog = true;
			this.rme_name = rmeName;
			this.data_agency = agency;
		},

		consulInformation(rmeName, comunidades) {
			this.dialogComunidades = true;
			this.rme_name = rmeName;
			this.data_consul = comunidades;
		},

		cleanWin(winName) {
			let win = winName.replaceAll("Ventanilla de", "");
			return win;
		},
	},
};
</script>
