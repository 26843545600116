import http from "@/services/http-common";
import authHeader from "@/services/auth-header";

class wsApiIme {
	getBanners() {
		return http.get(
			`/banner/?populate[itemBanner][populate][imageBanner][fields][0]=url`, //populate[image][populate][fields][0]=color&
			{
				//
				headers: authHeader(),
			}
		);
	}
}

export default new wsApiIme();
