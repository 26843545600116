<template>
	<v-app>
		<HeaderTemplate></HeaderTemplate>
		<v-main>
			<router-view />
		</v-main>
		<FooterTemplate></FooterTemplate>
		<HomeButtonDialogContainer />
	</v-app>
</template>

<script>
import HeaderTemplate from "@/components/template/Header.vue";
import FooterTemplate from "@/components/template/Footer.vue";
import HomeButtonDialogContainer from "@/containers/home/button-dialog.vue";

export default {
	name: "App",

	components: {
		HeaderTemplate,
		FooterTemplate,
		HomeButtonDialogContainer,
	},

	data: () => ({
		meta: {
			app_name: process.env.VUE_APP_NAME,
			base_url: process.env.VUE_APP_BASE_URL,
			endpoint: process.env.VUE_APP_ENDPOINT,
			storage_files: process.env.VUE_APP_STORAGE_FILES,
		},
	}),

	mounted() {
		document.title = `Red Global MX | ${this.meta.app_name}`;
	},
};
</script>
<style>
.banner-ime {
	position: fixed;
	z-index: 999;
	bottom: 40px;
	right: 40px;
}
</style>
