<template>
	<v-simple-table class="my-4">
		<template v-slot:default>
			<tbody>
				<tr v-if="dataConsul.name">
					<td>
						<small class="text-uppercase blue-grey--text">
							Consúl de asuntos Comunitarios
						</small>
						<p class="font-weight-medium">
							{{ dataConsul.name }}
						</p>
					</td>
				</tr>
				<tr v-if="dataConsul.position">
					<td>
						<small class="text-uppercase blue-grey--text"> Cargo </small>
						<p class="font-weight-medium">
							{{ dataConsul.position }}
						</p>
					</td>
				</tr>
				<tr v-if="dataConsul.assistants">
					<td>
						<small class="text-uppercase blue-grey--text">
							Personal de local de comunidades
						</small>
						<p class="font-weight-medium">
							{{ dataConsul.assistants }}
						</p>
					</td>
				</tr>
				<tr v-if="dataConsul.phone">
					<td>
						<small class="text-uppercase blue-grey--text"> Teléfono/Ext </small>
						<p class="font-weight-medium">
							{{ dataConsul.phone }}
							{{ dataConsul.extension ? "Ext." + dataConsul.extension : "" }}
						</p>
					</td>
				</tr>
				<tr v-if="dataConsul.mobile">
					<td>
						<small class="text-uppercase blue-grey--text">
							Teléfono Móvil
						</small>
						<p class="font-weight-medium">
							{{ dataConsul.mobile }}
						</p>
					</td>
				</tr>
				<tr v-if="dataConsul.email">
					<td>
						<small class="text-uppercase blue-grey--text">
							Correo electrónico
						</small>
						<p class="font-weight-medium">
							{{ dataConsul.email }}
						</p>
					</td>
				</tr>
			</tbody>
		</template>
	</v-simple-table>
</template>

<script>
export default {
	name: "RmePopupTableCommunities",

	props: {
		dataConsul: Object,
	},

	components: {},

	data: () => ({
		dialog: false,
		dialogComunidades: false,

		meta: {
			app_name: process.env.VUE_APP_NAME,
			app_version: process.env.VUE_APP_VERSION,
			base_url: process.env.VUE_APP_BASE_URL,
			endpoint: process.env.VUE_APP_ENDPOINT,
			storage_files: process.env.VUE_APP_STORAGE_FILES,
		},
	}),

	computed: {},

	mounted() {},

	methods: {},
};
</script>
