<template>
	<section class="blue-grey lighten-4 py-16">
		<v-container class="">
			<v-row>
				<v-col cols md="12" class="pt-12">
					<h2 class="display-2 text-uppercase text-center mb-4">
						<strong>Clústers</strong> Temáticos
					</h2>
				</v-col>
				<v-col
					cols
					md="3"
					v-for="(item, i) in getCluster"
					:key="i"
					class="pb-12">
					<ClusterCardContainer
						:image-cover="`${item.imageCover ? item.imageCover.url : ''}`"
						:icon="item.icon"
						:title="item.name"
						:slug="item.slug"
						:aspect-ratio="0.9" />
				</v-col>
			</v-row>
		</v-container>
	</section>
</template>

<script>
import ClusterCardContainer from "@/containers/cards/cluster-card.vue";
import wsApiRgmx from "@/services/api-rgmx";

export default {
	name: "ClustersComponent",

	components: {
		ClusterCardContainer,
	},

	data: () => ({
		getCluster: {},

		meta: {
			app_name: process.env.VUE_APP_NAME,
			base_url: process.env.VUE_APP_BASE_URL,
			endpoint: process.env.VUE_APP_ENDPOINT,
			storage_files: process.env.VUE_APP_STORAGE_FILES,
		},
	}),

	computed: {
		currentPage() {
			var result = this.$route.params.page;
			return result;
		},
	},

	mounted() {
		//document.title = `  | ${this.meta.app_name} | IME`;
		this.loadCluster();
	},

	methods: {
		loadCluster() {
			wsApiRgmx.getClusters().then(
				(response) => {
					this.getCluster = response.data.data;
				},
				(error) => {
					this.getCluster =
						(error.response && error.response.data) ||
						error.message ||
						error.toString();
				}
			);
		},

		addUrlToImage(htmlCode) {
			let url = htmlCode.replaceAll(
				"/uploads/",
				this.meta.storage_files + "/uploads/"
			);
			let style = url.replaceAll("src=", 'class="image-body" src=');
			return style;
		},
	},
};
</script>
