<template>
	<section>
		<slither-slider
			:options="{
				autoplay: true,
				transition: 'fade',
				dots: false,
				controls: false,
				fullscreen: false,
				animationDuration: multiplo,
			}">
			<div v-for="(item, i) in slides" :key="i">
				<v-img
					:src="meta.storage_files + addUrlToImage(item.url)"
					:lazy-src="meta.storage_files + addUrlToImage(item.url)"
					:class="`${selectedColor === '256,256,256,.0' ? 'over-grey' : ''} `"
					aspect-ratio="1"
					:gradient="`to bottom, rgba(0,0,0,.25), rgba(${selectedColor})`"
					:id="`time-${multiplo}`">
					<!-- {{ multiplo }} // {{ breakpoint.name }} -->
				</v-img>
			</div>
		</slither-slider>
	</section>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";

export default {
	name: "CollageSlideComponent",

	props: {
		slides: Array,
		animationTime: Number,
		multiplo: Number,
	},

	components: {
		VueperSlides,
		VueperSlide,
	},

	computed: {
		nameRoute: function () {
			return this.$route.name;
		},
		breakpoint: function () {
			return this.$vuetify.breakpoint;
		},
	},

	created() {
		const idx = Math.floor(Math.random() * this.colors.length);
		this.selectedColor = this.colors[idx];
	},

	mounted() {},

	data: () => ({
		colors: [
			"255,160,0,.75",
			"104,159,56,.75",
			"216,27,96,.75",
			"2,132,155,.75",
			"256,256,256,.0",
			"256,256,256,.0",
			"256,256,256,.0",
			"256,256,256,.0",
			"256,256,256,.0",
		],
		selectedColor: null,

		addUrlToImage(htmlCode) {
			let url = htmlCode.replaceAll("/uploads/", "/uploads/thumbnail_");
			return url;
		},

		meta: {
			app_name: process.env.VUE_APP_NAME,
			base_url: process.env.VUE_APP_BASE_URL,
			endpoint: process.env.VUE_APP_ENDPOINT,
			storage_files: process.env.VUE_APP_STORAGE_FILES,
		},
	}),
};
</script>
