<template>
	<div>
		<v-app-bar
			app
			fixed
			dark
			color="transparent"
			class="principal-menu white-glass elevation-0">
			<v-img
				alt="Red Global MX"
				class="logo shrink animate__animated animate__fadeInDown animate__faster"
				contain
				width="275"
				src="/assets/images/logos/RGMX-web.png"
				lazy-src="/assets/images/logos/RGMX-web.png"
				@click.stop="drawer = !drawer" />

			<v-spacer></v-spacer
			><!-- v-if="changeMenu" -->

			<v-btn
				tile
				color="#02849B"
				class="ma-1 text-capitalize elevation-0"
				href="/">
				Inicio </v-btn
			><!-- #02849B -->

			<v-btn
				tile
				color="pink darken-2"
				class="ma-1 text-capitalize elevation-0"
				href="/proyectos">
				Proyectos
			</v-btn>

			<v-btn
				tile
				color="orange darken-4"
				class="ma-1 text-capitalize elevation-0"
				href="/eventos">
				Eventos y Convocatorias
			</v-btn>

			<v-btn
				tile
				color="deep-purple lighten-1"
				class="ma-1 text-capitalize elevation-0"
				href="/clusters">
				Clusters
			</v-btn>

			<NavigationTopTemplate></NavigationTopTemplate>

			<v-btn
				tile
				color="orange darken-4"
				class="ma-1 text-capitalize elevation-0"
				href="/directorio">
				Directorio
			</v-btn>

			<v-btn
				tile
				color="deep-purple lighten-1"
				class="ma-1 text-capitalize elevation-0"
				href="/rgmx/lineamientos">
				Lineamientos
			</v-btn>
		</v-app-bar>
		<!-- v-if="changeMenu" -->
	</div>
</template>

<script>
import NavigationTopTemplate from "@/components/template/NavigationTop.vue";

export default {
	name: "HeaderTemplate",

	components: {
		NavigationTopTemplate,
	},

	data: () => ({
		changeMenu: false,
		drawer: null,
		meta: {
			app_name: process.env.VUE_APP_NAME,
			base_url: process.env.VUE_APP_BASE_URL,
			endpoint: process.env.VUE_APP_ENDPOINT,
			storage_files: process.env.VUE_APP_STORAGE_FILES,
		},
	}),

	mounted() {
		window.addEventListener("scroll", this.onScroll);
	},

	beforeDestroy() {
		window.removeEventListener("scroll", this.onScroll);
	},

	methods: {
		onScroll(e) {
			this.windowTop = e.target.documentElement.scrollTop;
			if (this.windowTop >= 150) {
				this.changeMenu = true;
			} else {
				this.changeMenu = false;
			}
		},
	},
};
</script>
