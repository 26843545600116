<template>
	<div>
		<v-img
			:src="imageCover ? imageCover : '/assets/images/back/rgmx7.jpg'"
			:lazy-src="imageCover ? imageCover : '/assets/images/back/rgmx7.jpg'"
			:class="classes"
			:aspect-ratio="aspectRatio"
			:gradient="`to bottom, rgba(0,0,0,.25), rgba(${selectedColor})`">
			<div
				class="fill-height gradient--pink d-flex flex-column align-center text-center justify-end pa-4">
				<div>
					<h6 class="mb-n1">
						<a
							@click="overlay = !overlay"
							class="text-h6 text-decoration-none white--text font-weight-bold text-shadow">
							{{ title }}
						</a>
					</h6>
					<span v-if="job" class="white--text text-shadow">{{ job }}</span>
				</div>
			</div>
		</v-img>

		<!-- <v-btn class="white--text" color="teal" @click="overlay = !overlay">
				Show Overlay
			</v-btn> -->

		<v-overlay
			:z-index="zIndex"
			:value="overlay"
			:opacity="opacity"
			color="black">
			<div class="video-responsive">
				<iframe
					:src="`https://www.youtube-nocookie.com/embed/${slug}`"
					:title="`Mexicanas en el Exterior`"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					referrerpolicy="strict-origin-when-cross-origin"
					allowfullscreen></iframe>
			</div>
			<v-btn
				fab
				class="white--text mt-3"
				color="orange darken-3"
				@click="overlay = false">
				<v-icon>mdi-close</v-icon>
			</v-btn>
			<div
				style="
					max-width: 960px;
					width: 780px;
					min-width: 360px;
					height: 10px;
				"></div>
		</v-overlay>
	</div>
</template>
<script>
//import wsApiRgmx from "@/services/api-rgmx";

export default {
	name: "TalentCardContainer",

	props: {
		imageCover: {
			type: String,
			default: "/assets/images/back/rgmx7.jpg",
		},
		title: {
			type: String,
			default: "Titulo",
		},
		slug: {
			type: String,
			default: "titulo",
		},
		job: {
			type: String,
		},
		classes: {
			type: String,
			default: "",
		},
		aspectRatio: {
			type: Number,
			default: 1.2,
		},
		freeBtnTitle: String,
		freeBtnSlug: String,
		freeBtnColor: String,
	},

	components: {},

	created() {
		const idx = Math.floor(Math.random() * this.colors.length);
		this.selectedColor = this.colors[idx];
	},

	data: () => ({
		overlay: false,
		zIndex: 999,
		opacity: 0.8,
		selectedColor: null,
		colors: [
			"255,160,0,.65",
			"104,159,56,.65",
			"216,27,96,.65",
			"2,132,155,.65",
			"94, 53, 177,.65",
		],
		meta: {
			app_name: process.env.VUE_APP_NAME,
			base_url: process.env.VUE_APP_BASE_URL,
			endpoint: process.env.VUE_APP_ENDPOINT,
			storage_files: process.env.VUE_APP_STORAGE_FILES,
		},
	}),

	computed: {
		currentPage() {
			var result = this.$route.params.chapter;
			return result;
		},
		currentToday() {
			var f = new Date();
			var j = f.toJSON();
			//console.log("🚀 ~ currentToday ~ j:", j);
			return j;
		},
	},

	mounted() {},

	methods: {},
};
</script>
