import httpGlobal from "./http-common-global";
import httpRgmx from "./http-common-rgmx";

const token_api_global = process.env.VUE_APP_TOKEN_API_GLOBAL;
const token_api_rgmx = process.env.VUE_APP_TOKEN_API_RGMX;

class wsMap {
	getAllRepresentations() {
		return httpGlobal.get(
			`/representations/?sort=agencies.agency_type.name:asc&sort=name:asc&filters[agencies][id][$null]=false&populate[community_consul][fields][0]=*&populate[agencies][populate][1]=agency_type&populate[agencies][sort][2]=agency_type.name:asc`, //&populate[agencies][populate][0]=agency_type
			{
				headers: { Authorization: "Bearer " + token_api_global },
			}
		);
	}

	getAgencyByType(agency_type) {
		return httpGlobal.get(
			`/agencies/?filters[agency_type][slug][$eq]=${agency_type}&populate[0]=agency_type&populate[1]=representation&filters[representation][id][$null]=false`,
			{
				headers: { Authorization: "Bearer " + token_api_global },
			}
		);
	}

	getRmeVaim() {
		return httpGlobal.get(
			`/representations/?filters[name][$containsi]=Consulmex`,
			{
				headers: { Authorization: "Bearer " + token_api_global },
			}
		);
	}

	/*
	 * community-centers
	 *
	 */

	getAllCommunityCenters() {
		return httpGlobal.get(
			`/community-centers/?pagination[pageSize]=275&populate=*`, //populate[representation][fields][0]=*&populate[office_type][fields][0]=*
			{
				headers: { Authorization: "Bearer " + token_api_global },
			}
		);
	}

	getCommunityCenters() {
		return httpGlobal.get(
			`/community-centers/?filters[office_type][id][$eq]=6&populate[representation][fields][0]=*`,
			{
				headers: { Authorization: "Bearer " + token_api_global },
			}
		);
	}

	/*
	 * configuration-map
	 *
	 */

	getConfigurationMap() {
		return httpGlobal.get(
			`/configuration-map/?populate=deep,3`, //populate[representation][fields][0]=*&populate[office_type][fields][0]=*
			{
				headers: { Authorization: "Bearer " + token_api_global },
			}
		);
	}

	getCommunityCenters() {
		return httpGlobal.get(
			`/community-centers/?filters[office_type][id][$eq]=6&populate[representation][fields][0]=*`,
			{
				headers: { Authorization: "Bearer " + token_api_global },
			}
		);
	}

	/* Regiones */
	getRegionsMap() {
		return httpRgmx.get(
			`/regions/?sort[1]=name:asc&fields[0]=name&fields[1]=slug&fields[2]=summary&populate[location][fields][3]=*&populate[contacto][fields][4]=*`,
			{
				headers: { Authorization: "Bearer " + token_api_rgmx },
			}
		);
	}

	/* Capitulos */
	getChaptersMap() {
		return httpRgmx.get(
			`/chapters/?sort[1]=title:asc&fields[0]=title&fields[1]=slug&fields[2]=summary&populate[geolocation][fields][3]=*&populate[region][fields][4]=name&populate[region][fields][5]=colorPin&populate[imageCover][fields][6]=url&populate[contacto][fields][7]=*`, //
			{
				headers: { Authorization: "Bearer " + token_api_rgmx },
			}
		);
	}

	/* Nodos */
	getNodesMap() {
		return httpRgmx.get(
			`/nodes/?sort[1]=title:asc&fields[0]=title&fields[1]=slug&fields[2]=summary&populate[geolocation][fields][3]=*&populate[Contacto][fields][4]=*&populate[social_media][fields][4]=*&populate[imageCover][fields][6]=url`,
			{
				headers: { Authorization: "Bearer " + token_api_rgmx },
			}
		);
	}

	/** Eventos **/
	getEventsMap(dateNow) {
		return httpRgmx.get(
			`/events/?filters[start][$gte]=${dateNow}&populate[imageCover][fields][5]=url&populate[chapter][fields][6]=title&populate[node][fields][7]=title&populate=geolocation`,
			{
				headers: { Authorization: "Bearer " + token_api_rgmx },
			}
		);
	}
}

export default new wsMap();
