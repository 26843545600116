import http from "@/services/http-common";
import authHeader from "@/services/auth-header";

class wsApiHome {
	/* Homepage */
	getHomePage() {
		return http.get(
			`/home-page/?populate[pillars][populate][0]=imageCover&populate[projectsConfig][populate][1]=background&populate[talentedMexicansConfig][populate][2]=background&populate[callAction][populate][fields][3]=deep,3`,
			{
				headers: authHeader(),
			}
		);
	}

	getHomePageTotal() {
		return http.get(`/home-page/?populate=deep,3`, {
			headers: authHeader(),
		});
	}

	getHomeMap() {
		return http.get(`/home-page/?populate[home_map][populate][fields][1]=*`, {
			headers: authHeader(),
		});
	}

	getAllArticles() {
		return http.get(`/home-page/`, { headers: authHeader() });
	}

	/* Collage */
	getCollage() {
		return http.get(
			`/collage/?populate[image][populate][pictures][fields][0]=url`, //populate[image][populate][fields][0]=color&
			{
				//
				headers: authHeader(),
			}
		);
	}

	/* Eventos */
	getEvents() {
		return http.get(
			`/events/?&populate[chapter][fields][0]=title&populate[node][fields][1]=title&populate[author][fields][2]=username&populate[imageCover][fields][2]=url`,
			{
				headers: authHeader(),
			}
		);
	}
}

export default new wsApiHome();
