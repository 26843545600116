<template>
	<div class="py-16 my-8 grey lighten-3">
		<v-container>
			<v-row>
				<v-col cols md="12" class="text-center">
					<h2
						class="display-2 text-uppercase text-center blue-grey--text text--darken-4 mb-4">
						<strong>Eventos</strong>
					</h2>
				</v-col>

				<v-col>
					<carousel
						:autoplay="true"
						:perPageCustom="[
							[320, 1],
							[480, 2],
							[768, 3],
							//[1199, 4],
						]"
						:centerMode="true"
						:loop="true"
						:speed="700"
						:autoplayTimeout="6000"
						navigationEnabled
						navigationNextLabel=""
						navigationPrevLabel="">
						<slide
							v-for="(item, i) in getAllEvents"
							:key="i"
							:data-index="item.id"
							:data-name="item.slug">
							<!-- style="width: 360px; max-width: 100%" -->

							<ImageCardContainer
								:image-cover="item.imageCover ? item.imageCover.url : ''"
								:title="item.name"
								:slug="item.slug"
								category="evento"
								:free-btn-title="item.start"
								free-btn-color="orange darken-3"
								:aspect-ratio="0.85"
								style="width: 360px; max-width: 100%" />
						</slide>
					</carousel>
				</v-col>

				<v-col cols md="12" class="text-right">
					<v-btn dark large color="pink darken-2" to="/eventos">
						Todos los eventos
						<v-icon>mdi-chevron-right</v-icon>
					</v-btn>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
import ImageCardContainer from "@/containers/cards/image-card.vue";
import wsApiHome from "@/services/api-home";

export default {
	name: "EventsComponent",

	components: {
		ImageCardContainer,
	},

	data: () => ({
		getAllEvents: {
			chapter: {},
			node: {},
			author: {},
			imageCover: {},
		},

		meta: {
			app_name: process.env.VUE_APP_NAME,
			base_url: process.env.VUE_APP_BASE_URL,
			endpoint: process.env.VUE_APP_ENDPOINT,
			storage_files: process.env.VUE_APP_STORAGE_FILES,
		},
	}),

	created() {},

	mounted() {
		this.getLastEvents();
	},

	methods: {
		getLastEvents() {
			wsApiHome.getEvents().then(
				(response) => {
					this.getAllEvents = response.data.data;
				},
				(error) => {
					this.getAllEvents =
						(error.response && error.response.data) ||
						error.message ||
						error.toString();
				}
			);
		},

		addUrlToImage(htmlCode) {
			let url = htmlCode.replaceAll("/uploads/", "/uploads/thumbnail_");
			return url;
		},
	},
};
</script>

<style>
.VueCarousel-navigation-button[data-v-453ad8cd] {
	top: 3%;
}

.VueCarousel-navigation-prev::before {
	font-family: "Material Design Icons";
	font-weight: 400;
	font-size: 4em;
	content: "\F0141";
}

.VueCarousel-navigation-next::before {
	font-family: "Material Design Icons";
	font-weight: 400;
	font-size: 4em;
	content: "\F0142";
}
.VueCarousel-dot {
	background-color: #9f2241 !important;
}
.VueCarousel-dot--active {
	background-color: #bc955c !important;
}
</style>
