<template>
	<section class="">
		<v-row
			dark
			class="rounded-xl elevation-10 py-10 px-16"
			style="
				background-color: #1c1d1f;
				background-image: url('/assets/images/back/estrellas.png');
				background-repeat: repeat;
			">
			<v-col md="12" class="text-center">
				<h1 class="white--text display-2">
					<!-- ¡Localiza un <strong>Nodo</strong> o <strong>Capítulo</strong> y
					súmate! -->
					<span v-html="mapTitle"></span>
				</h1>
				<p class="lead- white--text mt-4" style="text-align: justify">
					Este <strong>mapa interactivo</strong> muestra algunos de los
					servicios que proporciona la
					<strong>Secretaría de Relaciones Exteriores</strong> a través del
					<strong>Instituto de los Mexicanos en el Exterior</strong> y de la
					<strong
						>Dirección General de Protección Consular y Planeación
						Estratégica</strong
					>
					para nuestros connacionales residentes fuera de México. Estos
					servicios se brindan a través de
					<strong>Ventanillas Comunitarias</strong> ubicadas en los
					<strong>Consulados</strong> y <strong>Embajadas de México</strong>.
					Presiona la ventanilla de tu interés en el lado izquierdo y verás cómo
					se despliegan en el mapa.
				</p>
			</v-col>

			<v-col
				cols
				md="3"
				class=""
				:style="`overflow-x: auto; height: ${mapHeight} !important`">
				<!-- ! Treeview -->

				<v-list flat dense>
					<v-subheader class="pl-5 strong">OPCIONES</v-subheader>
					<v-list-item @click="mf.status ? filtersClose() : filtersAll()">
						<v-list-item-icon>
							<v-icon>{{
								mf.status ? "mdi-playlist-remove" : "mdi-playlist-check"
							}}</v-icon>
						</v-list-item-icon>
						<v-list-item-title class="strong">
							{{ mf.status ? "Cerrar todo" : "Mostar todo" }}</v-list-item-title
						>
					</v-list-item>

					<v-list-item
						@click="
							filterVaim(
								mf.vaim ? false : true,
								vaimSnack.title,
								vaimSnack.description,
								vaimSnack.imageLogo
							)
						">
						<v-list-item-icon>
							<v-avatar size="22">
								<img
									src="./images/icon-vaim.png"
									alt="Ventanilla de Atención Integral a la Mujer" />
							</v-avatar>
						</v-list-item-icon>
						<v-list-item-title :class="mf.vaim ? 'strong' : ''"
							>VAIM</v-list-item-title
						>
					</v-list-item>

					<!-- todo: VENTANILLAS -->
					<v-list-group :value="false">
						<template v-slot:activator>
							<v-list-item-icon>
								<!-- <v-icon>mdi-vuejs</v-icon> -->
								<v-avatar size="22">
									<img src="./images/icon-ime.webp" alt="IME" />
								</v-avatar>
							</v-list-item-icon>
							<v-list-item-title :class="mf.ventanillas ? 'strong' : ''">
								Ventanillas Comunitarias
							</v-list-item-title>
						</template>

						<v-list-item
							link
							class="pl-8"
							@click="
								filterVentanillas(
									mf.ventanillas ? false : true,
									comunidades.ventanillaComunitaria.title,
									comunidades.ventanillaComunitaria.description,
									comunidades.ventanillaComunitaria.imageLogo
								)
							">
							<v-list-item-icon>
								<v-icon>mdi-check-all</v-icon>
							</v-list-item-icon>
							<v-list-item-title :class="mf.ventanillas ? 'strong' : ''"
								>Mostar todas</v-list-item-title
							>
						</v-list-item>

						<v-list-item
							v-for="(item, i) in cfgMapOffice"
							:key="i"
							link
							class="pl-8"
							@click="
								filterTypeOffice(
									item.office_type.slug,
									item.office_type.name,
									item.description,
									item.imageLogo
										? 'https://api-global.k0s.ime.red' + item.imageLogo.url
										: 'https://api-global.k0s.ime.red/uploads/IME_Logo_Vertical_2b4ff3b4ca.png'
								)
							">
							<v-list-item-icon>
								<v-avatar v-if="item.office_type.iconCustom" size="22">
									<img
										:src="`https://api-global.k0s.ime.red/${item.office_type.iconCustom}`"
										alt="IME" />
								</v-avatar>
								<v-icon v-else> mdi-{{ item.office_type.icon }}</v-icon>
							</v-list-item-icon>
							<v-list-item-title
								:class="
									item.office_type.slug === typeOfficeStatus ? 'strong' : ''
								">
								{{ item.office_type.altName }} {{ item.office_type.iconCustom }}
							</v-list-item-title>
						</v-list-item>
					</v-list-group>
					<!-- todo: /VENTANILLAS -->

					<v-list-item
						@click="
							filterPlazasComunitarias(
								mf.plazasComunitarias ? false : true,
								comunidades.plazaComunitaria.title,
								comunidades.plazaComunitaria.description,
								'https://api-global.k0s.ime.red/uploads/logo_plazas_7a4cd882c6.png'
							)
						">
						<v-list-item-icon>
							<v-icon>mdi-book-open-page-variant-outline</v-icon>
						</v-list-item-icon>
						<v-list-item-title :class="mf.plazasComunitarias ? 'strong' : ''"
							>Plazas Comunitarias</v-list-item-title
						>
					</v-list-item>

					<!-- * /MapCenter-->
					<v-list-group :value="false">
						<template v-slot:activator>
							<v-list-item-icon>
								<!-- <v-icon>mdi-vuejs</v-icon> -->
								<v-avatar size="22">
									<img src="./images/icon-rgmx.png" alt="IME" />
								</v-avatar>
							</v-list-item-icon>
							<v-list-item-title
								link
								:class="mf.getNodes || mf.getChapters ? 'strong' : ''">
								Red Global MX
							</v-list-item-title>
						</template>

						<v-list-item
							link
							class="pl-8"
							@click="
								filterGetEventsRgmx(
									mf.eventsRgmx ? false : true,
									redGlobal.rgmx.title,
									redGlobal.rgmx.description,
									redGlobal.rgmx.imageLogo
								)
							">
							<v-list-item-icon>
								<v-avatar size="22">
									<img src="./images/icon-rgmx.png" alt="Red Global MX" />
								</v-avatar>
							</v-list-item-icon>
							<v-list-item-title :class="mf.eventsRgmx ? 'strong' : ''">
								<span>Eventos de la RGMX </span>
							</v-list-item-title>
						</v-list-item>

						<v-list-item
							link
							class="pl-8"
							@click="
								filterGetNodes(
									mf.getNodes ? false : true,
									redGlobal.nodo.title,
									redGlobal.nodo.description,
									redGlobal.nodo.imageLogo
								)
							">
							<v-list-item-icon>
								<v-avatar size="22">
									<img src="./images/icon-rgmx.png" alt="Red Global MX" />
								</v-avatar>
							</v-list-item-icon>
							<v-list-item-title :class="mf.getNodes ? 'strong' : ''">
								Nodos
							</v-list-item-title>
						</v-list-item>

						<v-list-item
							link
							class="pl-8"
							@click="
								filterGetChapters(
									mf.getChapters ? false : true,
									redGlobal.capitulo.title,
									redGlobal.capitulo.description,
									redGlobal.capitulo.imageLogo
								)
							">
							<v-list-item-icon>
								<v-avatar size="22">
									<img src="./images/icon-rgmx.png" alt="Red Global MX" />
								</v-avatar>
							</v-list-item-icon>
							<v-list-item-title :class="mf.getChapters ? 'strong' : ''">
								Capítulos
							</v-list-item-title>
						</v-list-item>
						<v-list-item
							link
							class="pl-8"
							@click="
								filterGetRegions(
									mf.getRegions ? false : true,
									redGlobal.region.title,
									redGlobal.region.description,
									redGlobal.region.imageLogo
								)
							">
							<v-list-item-icon>
								<v-avatar size="22">
									<img src="./images/icon-rgmx.png" alt="Red Global MX" />
								</v-avatar>
							</v-list-item-icon>
							<v-list-item-title :class="mf.getRegions ? 'strong' : ''">
								Regiones
							</v-list-item-title>
						</v-list-item>

						<v-divider v-if="mf.getNodes || mf.getChapters"></v-divider>

						<v-subheader
							class="pl-5 strong"
							v-if="mf.getNodes || mf.getChapters">
							ITEMS
						</v-subheader>

						<!-- todo: NODOS -->
						<v-list-group v-if="mf.getNodes" :value="false" no-action sub-group>
							<template v-slot:activator>
								<v-list-item-content>
									<v-list-item-title>Nodos</v-list-item-title>
								</v-list-item-content>
							</template>

							<v-list-item
								v-for="(geoNode, i) in getGeoNode"
								:key="i"
								@click="
									centerPosition(
										geoNode.geolocation.longitud,
										geoNode.geolocation.latitud,
										8.0
									)
								">
								<v-list-item-title>
									{{ geoNode.title }}
								</v-list-item-title>
							</v-list-item>
						</v-list-group>
						<!-- todo: /NODOS -->

						<!-- todo: CAPITILOS -->
						<v-list-group
							v-if="mf.getChapters"
							:value="false"
							no-action
							sub-group>
							<template v-slot:activator>
								<v-list-item-content>
									<v-list-item-title>Capítulos</v-list-item-title>
								</v-list-item-content>
							</template>

							<v-list-item
								v-for="(getGeoChapter, i) in getGeoChapter"
								:key="i"
								@click="
									centerPosition(
										getGeoChapter.geolocation.longitud,
										getGeoChapter.geolocation.latitud,
										12.0
									)
								">
								<v-list-item-title>
									{{ getGeoChapter.title }}
								</v-list-item-title>
							</v-list-item>
						</v-list-group>
						<!-- todo: /CAPITILOS -->
					</v-list-group>

					<v-divider v-if="mf.ventanillas"></v-divider>
				</v-list>
				<!-- ! / Treeview -->
			</v-col>

			<v-col cols md="9" class="mb-0">
				<MglMap
					:accessToken="accessToken"
					:mapStyle.sync="mapStyle"
					:zoom="zoom"
					:center="center"
					:attributionControl="true"
					@load="onMapLoaded">
					<MglNavigationControl position="top-left" />
					<MglGeolocateControl position="top-left" />
					<!-- <MglFullscreenControl position="top-left" /> -->

					<MglMarker
						v-if="mf.vaim"
						v-for="(item, i) in vaim"
						:key="`vaim-${i}`"
						:coordinates="[
							item.latitud ? item.latitud : i,
							item.longitud ? item.longitud : i,
						]"
						color="#F4511E">
						<MglPopup anchor="top">
							<VaimMap :geoData="item" />
						</MglPopup>
					</MglMarker>

					<MglMarker
						v-if="mf.ventanillas"
						v-for="(item, i) in representations"
						:key="`rme-${i}`"
						:coordinates="[
							item.latitud ? item.latitud : i,
							item.longitud ? item.longitud : i,
						]"
						color="#9f2241">
						<MglPopup anchor="top">
							<RmeMap :geoData="item" />
						</MglPopup>
					</MglMarker>

					<MglMarker
						v-if="mf.typeOffice"
						v-for="(item, i) in agenciesType"
						:key="`office-${i}`"
						:coordinates="[
							item.representation.latitud ? item.representation.latitud : i,
							item.representation.longitud ? item.representation.longitud : i,
						]"
						color="#9f2241">
						<MglPopup anchor="top">
							<AgencyMap :geoData="item" />
						</MglPopup>
					</MglMarker>

					<MglMarker
						v-if="mf.plazasComunitarias"
						v-for="(item, i) in plazas"
						:key="`plaza-${i}`"
						:coordinates="[
							item.longitud ? item.longitud : -1 + i,
							item.latitud ? item.latitud : 1 + i,
						]"
						color="#00897B">
						<MglPopup anchor="top">
							<PlazaComunitariaMap :geoData="item" />
						</MglPopup>
					</MglMarker>

					<div
						class="events-map"
						v-if="mf.eventsRgmx"
						v-for="(item, i) in getEvents">
						<MglMarker
							v-if="item.geolocation"
							:key="`event-${i}`"
							:coordinates="[
								item.geolocation ? item.geolocation.longitud : -1 + i,
								item.geolocation ? item.geolocation.latitud : 1 + i,
							]"
							color="#D500F9">
							<MglPopup anchor="top">
								<EventsRmgxMap :geoData="item" />
								<!-- <v-row>
									<v-col>
										<pre>{{ item }}</pre>
									</v-col>
								</v-row> -->
							</MglPopup>
						</MglMarker>
					</div>

					<MglMarker
						v-if="mf.getNodes"
						v-for="(node, i) in getGeoNode"
						:key="`getGeoNode-${i}`"
						:coordinates="[
							node.geolocation.longitud ? node.geolocation.longitud : -1 + i,
							node.geolocation.latitud ? node.geolocation.latitud : 1 + i,
						]"
						color="#FF4081">
						<MglPopup anchor="bottom">
							<v-row>
								<v-col cols md="12" class="mt-3 text-center">
									<v-img
										v-if="node.imageCover"
										:lazy-src="`${meta.storage_files + node.imageCover.url}`"
										:src="`${meta.storage_files + node.imageCover.url}`"
										aspect-ratio="1.6"
										max-height="55"
										max-width="275"
										class="mx-auto">
									</v-img>
									<h2
										v-else
										class="text-h5 font-weight-bold text-center mt-2 mb-4">
										Nodo {{ node.title }}
									</h2>

									<v-card max-height="550" class="elevation--4">
										<v-card-text>
											<v-simple-table class="my-1">
												<template v-slot:default>
													<tbody>
														<tr v-if="node.summary">
															<td>
																<small class="text-uppercase blue-grey--text">
																	Acerca del Nodo
																</small>
																<p class="font-weight-medium">
																	{{ node.summary }}
																</p>
															</td>
														</tr>
														<tr v-if="node.Contacto">
															<td>
																<small class="text-uppercase blue-grey--text">
																	Presidente del Nodo
																</small>
																<p class="font-weight-medium text-capitalize">
																	{{ node.Contacto.name }}
																</p>
															</td>
														</tr>
														<tr v-if="node.Contacto">
															<td>
																<small class="text-uppercase blue-grey--text">
																	Contacto
																</small>
																<ul
																	class="no-list font-weight-medium text-capitalize">
																	<li v-if="node.Contacto.email">
																		<v-icon left>mdi-at</v-icon>
																		{{ node.Contacto.email }}
																	</li>
																	<li v-if="node.Contacto.phone">
																		<v-icon left>mdi-phone-classic</v-icon>
																		{{ node.Contacto.phone }}
																	</li>
																	<li v-if="node.Contacto.mobile">
																		<v-icon left>mdi-phone</v-icon>
																		{{ node.Contacto.mobile }}
																	</li>
																</ul>
															</td>
														</tr>
														<tr v-if="node.social_media">
															<td>
																<small class="text-uppercase blue-grey--text">
																	Redes Sociales
																</small>
																<ul
																	class="no-list font-weight-medium text-capitalize">
																	<li v-if="node.social_media.facebook">
																		<v-icon left>mdi-facebook</v-icon>
																		{{ node.social_media.facebook }}
																	</li>
																	<li v-if="node.social_media.twitter">
																		<v-icon left>mdi-twitter</v-icon>
																		{{ node.social_media.twitter }}
																	</li>
																	<li v-if="node.social_media.instagram">
																		<v-icon left>mdi-instagram</v-icon>
																		{{ node.social_media.instagram }}
																	</li>
																</ul>
															</td>
														</tr>
													</tbody>
												</template>
											</v-simple-table>
										</v-card-text>
									</v-card>
								</v-col>
							</v-row>
						</MglPopup>
					</MglMarker>

					<MglMarker
						v-if="mf.getChapters"
						v-for="(chapter, i) in getGeoChapter"
						:key="`getGeoChapter-${i}`"
						:coordinates="[
							chapter.geolocation.longitud
								? chapter.geolocation.longitud
								: -1 + i,
							chapter.geolocation.latitud ? chapter.geolocation.latitud : 1 + i,
						]"
						:color="chapter.region.colorPin">
						<MglPopup anchor="bottom">
							<v-row>
								<v-col cols md="12" class="mt-3 text-center">
									<v-img
										v-if="chapter.imageCover"
										:lazy-src="`${meta.storage_files + chapter.imageCover.url}`"
										:src="`${meta.storage_files + chapter.imageCover.url}`"
										aspect-ratio="1.6"
										max-height="55"
										max-width="275"
										class="mx-auto">
									</v-img>
									<h2
										v-else
										class="text-h5 font-weight-bold text-center mt-2 mb-4">
										Capítulo {{ chapter.title }}
									</h2>

									<v-card max-height="550" class="elevation--4">
										<v-card-text>
											<v-simple-table class="my-1">
												<template v-slot:default>
													<tbody>
														<tr v-if="chapter.summary">
															<td>
																<small class="text-uppercase blue-grey--text">
																	Acerca del Capítulo
																</small>
																<p class="font-weight-medium">
																	{{ chapter.summary }}
																</p>
															</td>
														</tr>
														<tr v-if="chapter.contacto">
															<td>
																<small class="text-uppercase blue-grey--text">
																	Presidente del Nodo
																</small>
																<p class="font-weight-medium text-capitalize">
																	{{ chapter.contacto.name }}
																</p>
															</td>
														</tr>
														<tr v-if="chapter.contacto">
															<td>
																<small class="text-uppercase blue-grey--text">
																	Contacto
																</small>
																<ul
																	class="no-list font-weight-medium text-capitalize">
																	<li v-if="chapter.contacto.email">
																		<v-icon left>mdi-at</v-icon>
																		{{ chapter.contacto.email }}
																	</li>
																	<li v-if="chapter.contacto.phone">
																		<v-icon left>mdi-phone-classic</v-icon>
																		{{ chapter.contacto.phone }}
																	</li>
																	<li v-if="chapter.contacto.mobile">
																		<v-icon left>mdi-phone</v-icon>
																		{{ chapter.contacto.mobile }}
																	</li>
																</ul>
															</td>
														</tr>
														<tr v-if="chapter.social_media">
															<td>
																<small class="text-uppercase blue-grey--text">
																	Redes Sociales
																</small>
																<ul
																	class="no-list font-weight-medium text-capitalize">
																	<li v-if="chapter.social_media.facebook">
																		<v-icon left>mdi-facebook</v-icon>
																		{{ chapter.social_media.facebook }}
																	</li>
																	<li v-if="chapter.social_media.twitter">
																		<v-icon left>mdi-twitter</v-icon>
																		{{ chapter.social_media.twitter }}
																	</li>
																	<li v-if="chapter.social_media.instagram">
																		<v-icon left>mdi-instagram</v-icon>
																		{{ chapter.social_media.instagram }}
																	</li>
																</ul>
															</td>
														</tr>
													</tbody>
												</template>
											</v-simple-table>
										</v-card-text>
									</v-card>
								</v-col>
							</v-row>
						</MglPopup>
					</MglMarker>

					<MglMarker
						v-if="mf.getRegions"
						v-for="(region, i) in getGeoRegion"
						:key="`getGeoRegion-${i}`"
						:coordinates="[
							region.location.longitud ? region.location.longitud : -1 + i,
							region.location.latitud ? region.location.latitud : 1 + i,
						]"
						color="#FF6F00">
						<MglPopup anchor="bottom">
							<v-row>
								<v-col cols md="12" class="mt-3 text-center">
									<v-img
										v-if="region.imageCover"
										:lazy-src="`${meta.storage_files + region.imageCover.url}`"
										:src="`${meta.storage_files + region.imageCover.url}`"
										aspect-ratio="1.6"
										max-height="55"
										max-width="275"
										class="mx-auto">
									</v-img>
									<h2
										v-else
										class="text-h5 font-weight-bold text-center mt-2 mb-4">
										Región {{ region.name }}
									</h2>

									<v-card max-height="550" class="elevation--4">
										<v-card-text>
											<v-simple-table class="my-1">
												<template v-slot:default>
													<tbody>
														<tr v-if="region.summary">
															<td>
																<small class="text-uppercase blue-grey--text">
																	Acerca de la Región
																</small>
																<p class="font-weight-medium">
																	{{ region.summary }}
																</p>
															</td>
														</tr>
														<tr v-if="region.contacto">
															<td>
																<small class="text-uppercase blue-grey--text">
																	Presidente de la Región
																</small>
																<p class="font-weight-medium text-capitalize">
																	{{ region.contacto.name }}
																</p>
															</td>
														</tr>
														<tr v-if="region.contacto">
															<td>
																<small class="text-uppercase blue-grey--text">
																	Contacto
																</small>
																<ul
																	class="no-list font-weight-medium text-capitalize">
																	<li v-if="region.contacto.email">
																		<v-icon left>mdi-at</v-icon>
																		{{ region.contacto.email }}
																	</li>
																	<li v-if="region.contacto.phone">
																		<v-icon left>mdi-phone-classic</v-icon>
																		{{ region.contacto.phone }}
																	</li>
																	<li v-if="region.contacto.mobile">
																		<v-icon left>mdi-phone</v-icon>
																		{{ region.contacto.mobile }}
																	</li>
																</ul>
															</td>
														</tr>
														<tr v-if="region.social_media">
															<td>
																<small class="text-uppercase blue-grey--text">
																	Redes Sociales
																</small>
																<ul
																	class="no-list font-weight-medium text-capitalize">
																	<li v-if="region.social_media.facebook">
																		<v-icon left>mdi-facebook</v-icon>
																		{{ region.social_media.facebook }}
																	</li>
																	<li v-if="region.social_media.twitter">
																		<v-icon left>mdi-twitter</v-icon>
																		{{ region.social_media.twitter }}
																	</li>
																	<li v-if="region.social_media.instagram">
																		<v-icon left>mdi-instagram</v-icon>
																		{{ region.social_media.instagram }}
																	</li>
																</ul>
															</td>
														</tr>
													</tbody>
												</template>
											</v-simple-table>
										</v-card-text>
									</v-card>
								</v-col>
							</v-row>
						</MglPopup>
					</MglMarker>
				</MglMap>
			</v-col>
		</v-row>

		<!-- <v-row>
			<pre>{{ agenciesType }}</pre>
		</v-row> -->

		<div v-if="snack.status" class="snacks">
			<v-snackbar
				v-model="snack.status"
				:timeout="snack.timeout"
				shaped
				bottom
				right
				color="light-green darken-3 pa-4">
				<v-row>
					<v-col cols md="3" class="d-flex align-center">
						<v-img
							:lazy-src="snack.imageLogo ? snack.imageLogo : imageDefault"
							:src="snack.imageLogo ? snack.imageLogo : imageDefault"
							contain
							width="125px"
							class="white ma-1">
						</v-img>
					</v-col>
					<v-col>
						<h2 class="text-h5 font-weight-bold mb-2 white--text">
							{{ snack.title }}
						</h2>
						<div v-html="snack.description" class="pr-4"></div>
					</v-col>
				</v-row>

				<template v-slot:action="{ attrs }">
					<v-btn
						light
						fab
						v-bind="attrs"
						@click="snack.status = false"
						class="light-green lighten-4 elevation-0">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</template>
			</v-snackbar>
		</div>
	</section>
</template>

<script>
import wsMap from "./ws-map";
// import wsMap from "@/services/api-global";
import wsApiRgmx from "@/services/api-rgmx";

import PlazaComunitariaMap from "./plaza_comunitaria.vue";
import EventsRmgxMap from "./events-rgmx.vue";
import AgencyMap from "./agency_type.vue";
import VaimMap from "./vaim.vue";
import RmeMap from "./rme.vue";

import Mapbox from "mapbox-gl";
import {
	MglMap,
	MglAttributionControl,
	MglNavigationControl,
	MglGeolocateControl,
	MglFullscreenControl,
	MglScaleControl,
	MglPopup,
	MglMarker,
} from "vue-mapbox";

export default {
	name: "MapContainer",

	props: {
		mapTitle: {
			type: String,
			default: "Localiza servicios de México en el Mundo",
		},
		mapHeight: {
			type: String,
			default: "50hv",
		},
	},

	components: {
		MglMap,
		MglAttributionControl,
		MglNavigationControl,
		MglGeolocateControl,
		MglFullscreenControl,
		MglScaleControl,
		MglPopup,
		MglMarker,
		RmeMap,
		PlazaComunitariaMap,
		AgencyMap,
		VaimMap,
		EventsRmgxMap,
	},

	data: () => ({
		imageDefault:
			"https://api-global.k0s.ime.red/uploads/IME_Logo_Vertical_2b4ff3b4ca.png",
		toggle_exclusive: undefined,
		typeOfficeStatus: "",
		mf: {
			status: "",
			statusIcon: true,
			ventanillas: true,
			plazasComunitarias: false,
			getNodes: false,
			getChapters: false,
			getRegions: false,
			typeOffice: false,
			vaim: false,
			eventsRgmx: false,
			// rmeBySlug: false,
		},
		snack: {
			status: false,
			timeout: 25000,
			title: "",
			description: "",
			imageLogo: "",
		},
		vaimSnack: {
			title: "Política de Vinculación y Atención Integral para la Mujer",
			description:
				"<p>Es una política pública que busca incorporar la perspectiva de género a las actividades efectuadas por las representaciones de México en el exterior. Sus objetivos son proteger a las mujeres víctimas de violencia conyugal y de pareja, así como promover el empoderamiento y la igualdad de género.</p>",
			imageLogo: "https://api-global.k0s.ime.red/uploads/vaim_c135f2de2b.png",
		},
		comunidades: {
			ventanillaComunitaria: {
				title: "Ventanillas Comunitarias",
				description:
					"<p>Las Ventanillas de Atención Comunitaria, son centros de atención establecidos por el Instituto de los Mexicanos en el Exterior (IME) en consulados y embajadas mexicanas alrededor del mundo. Estas ventanillas tienen como objetivo proporcionar servicios y asistencia a la comunidad mexicana que reside fuera del país.</p>",
				imageLogo: "",
			},
			plazaComunitaria: {
				title: "Plaza Comunitaria",
				description:
					"<p>Centros de reunión que ofrecen diversos servicios comunitarios y educativos en Estados Unidos, donde las personas mayores de 15 años mexican@s o de origen mexican@, sin importar su situación migratoria, tienen la oportunidad de iniciar, continuar o finalizar sus estudios de nivel básico.</p>",
				imageLogo:
					"https://api-global.k0s.ime.red/uploads/logo_plazas_7a4cd882c6.png",
			},
		},
		redGlobal: {
			rgmx: {
				title: "Red Global MX",
				description:
					"<p>La Red Global Mx reúne a mexicanas y mexicanos calificados interesados en la promoción del desarrollo de México y en particular a través de la inserción de México en la economía del conocimiento. 72 capítulos distribuidos en 35 países</p>",
				imageLogo:
					"https://api-global.k0s.ime.red/uploads/red_global_red_global_mx_78798109a2.png",
			},
			capitulo: {
				title: "Capítulo",
				description:
					"<p>Existen 72 capítulos distribuidos en 35 países, su objetivo es identificar y organizar a los mexicanos altamente cualificados que viven en cada país y generan actividades de cooperación y proyectos específicos que fomentan el desarrollo económico, social, científico, artístico, tanto de sus integrantes, como de su entorno.</p>",
				imageLogo:
					"https://api-global.k0s.ime.red/uploads/red_global_red_global_mx_78798109a2.png",
			},
			nodo: {
				title: "Nodo",
				description:
					"<p>Existen 19 Nodos, que son las contrapartes mexicanas de los Capítulos, conformadas por sector privado, público y/o universidades estatales y apoyan a los Capítulos con los proyectos a nivel local.</p>",
				imageLogo:
					"https://api-global.k0s.ime.red/uploads/red_global_red_global_mx_78798109a2.png",
			},
			region: {
				title: "Región",
				description:
					"<p>Es la encargada de supervisar el funcionamiento y colaborar en las actividades de sus respectivos Capítulos</p>",
				imageLogo:
					"https://api-global.k0s.ime.red/uploads/red_global_red_global_mx_78798109a2.png",
			},
		},

		cfgMapOtherLinks: [],
		cfgMapOffice: [],
		cfgMapRgmx: [],
		getGeoNode: {},
		getGeoChapter: {},
		getGeoRegion: {},
		homeMap: {},
		offices: {},
		plazas: {},
		representations: {},
		agenciesType: {},
		vaim: {},
		getEvents: {},

		accessToken: process.env.VUE_APP_MAPBOX,
		mapStyle: "mapbox://styles/mapbox/outdoors-v12",
		center: [-99.1345574, 19.4450822],
		zoom: 1.4,

		meta: {
			app_name: process.env.VUE_APP_NAME,
			base_url: process.env.VUE_APP_BASE_URL,
			endpoint: process.env.VUE_APP_ENDPOINT,
			storage_files: process.env.VUE_APP_STORAGE_FILES,
		},
	}),

	computed: {
		currentToday() {
			var f = new Date();
			var j = f.toJSON();
			return j;
		},
	},

	mounted() {
		document.title = `${this.meta.app_name}`;
		this.loadAllRepresentations();
		this.loadAllRmeVaim();
		this.loadAllOfficesPlazas();
		this.loadNodesLatLong();
		this.loadChaptersLatLong();
		this.loadRegionsLatLong();
		// this.loadRmeBySlug();
		this.loadCfgMap();
		this.loadEvents(this.currentToday);
	},

	created() {
		this.mapbox = Mapbox;
	},

	filters: {
		timeFormat(value) {
			const duration = moment.duration(value);
			return duration.hours() + "h " + duration.minutes() + "s";
		},
	},

	methods: {
		onMapLoaded(event) {
			this.map = event.map;
			this.$store.map = event.map;
		},

		loadCfgMap() {
			wsMap.getConfigurationMap().then(
				(response) => {
					this.cfgMapOffice = response.data.data.office; //
					this.cfgMapOtherLinks = response.data.data.other_links;
					this.cfgMapRgmx = response.data.data.rgmx;
				},
				(error) => {
					this.cfgMap =
						(error.response && error.response.data) ||
						error.message ||
						error.toString();
				}
			);
		},

		loadAllRepresentations() {
			wsMap.getAllRepresentations().then(
				(response) => {
					this.representations = response.data.data;
				},
				(error) => {
					this.representations =
						(error.response && error.response.data) ||
						error.message ||
						error.toString();
				}
			);
		},

		loadAllRmeVaim() {
			wsMap.getRmeVaim().then(
				(response) => {
					this.vaim = response.data.data;
					//console.log("🚀 ~ loadAllRepresentations ~ this.vaim:", this.vaim);
				},
				(error) => {
					this.vaim =
						(error.response && error.response.data) ||
						error.message ||
						error.toString();
				}
			);
		},

		loadAgenciesByType(agency_type) {
			wsMap.getAgencyByType(agency_type).then(
				(response) => {
					this.agenciesType = response.data.data;
					this.representations = "";
					// console.log(
					// 	"🚀 ~ loadAgenciesByType ~ this.agenciesType:",
					// 	this.agenciesType
					// );
				},
				(error) => {
					this.agenciesType =
						(error.response && error.response.data) ||
						error.message ||
						error.toString();
				}
			);
		},

		loadAllOfficesPlazas() {
			wsMap.getAllCommunityCenters().then(
				(response) => {
					this.plazas = response.data.data;
				},
				(error) => {
					this.plazas =
						(error.response && error.response.data) ||
						error.message ||
						error.toString();
				}
			);
		},

		loadNodesLatLong() {
			wsMap.getNodesMap().then(
				(response) => {
					this.getGeoNode = response.data.data;
				},
				(error) => {
					this.getGeoNode =
						(error.response && error.response.data) ||
						error.message ||
						error.toString();
				}
			);
		},

		loadChaptersLatLong() {
			wsMap.getChaptersMap().then(
				(response) => {
					this.getGeoChapter = response.data.data;
				},
				(error) => {
					this.getGeoChapter =
						(error.response && error.response.data) ||
						error.message ||
						error.toString();
				}
			);
		},

		loadRegionsLatLong() {
			wsMap.getRegionsMap().then(
				(response) => {
					this.getGeoRegion = response.data.data;
				},
				(error) => {
					this.getGeoRegion =
						(error.response && error.response.data) ||
						error.message ||
						error.toString();
				}
			);
		},

		loadEvents(dateNow) {
			wsMap.getEventsMap(dateNow).then(
				(response) => {
					this.getEvents = response.data.data;
					console.log("🚀 ~ wsMap ~ this.getEvents:", this.getEvents);
					//	console.log("🚀 ~ wsMap ~ dateNow:", dateNow);
				},
				(error) => {
					this.getEvents =
						(error.response && error.response.data) ||
						error.message ||
						error.toString();
				}
			);
		},

		filtersAll() {
			this.loadAllRepresentations();

			this.mf.status = true;
			this.mf.statusIcon = true;
			this.mf.ventanillas = true;
			this.mf.plazasComunitarias = true;
			this.mf.getNodes = true;
			this.mf.getChapters = true;
			this.mf.getRegions = true;
			this.mf.vaim = true;
			this.mf.eventsRgmx = true;
			// this.mf.rmeBySlug = true;
		},

		filtersClose() {
			this.mf.status = false;
			this.mf.statusIcon = false;
			this.mf.ventanillas = false;
			this.mf.plazasComunitarias = false;
			this.mf.getNodes = false;
			this.mf.getChapters = false;
			this.mf.getRegions = false;
			this.mf.vaim = false;
			this.mf.eventsRgmx = false;
			// this.mf.rmeBySlug = false;
		},

		filterVentanillas(status, title, message, imageLogo) {
			this.mf.ventanillas = status;
			this.snack.status = true;
			this.loadAllRepresentations();
			this.openSnackbar(title, message, imageLogo);
		},

		filterPlazasComunitarias(status, title, message, imageLogo) {
			this.mf.plazasComunitarias = status;
			this.snack.status = true;
			this.openSnackbar(title, message, imageLogo);
		},

		filterGetNodes(status, title, message, imageLogo) {
			this.mf.getNodes = status;
			this.snack.status = true;
			this.openSnackbar(title, message, imageLogo);
		},

		filterGetChapters(status, title, message, imageLogo) {
			this.mf.getChapters = status;
			this.snack.status = true;
			this.openSnackbar(title, message, imageLogo);
		},

		filterGetRegions(status, title, message, imageLogo) {
			this.mf.getRegions = status;
			this.snack.status = true;
			this.openSnackbar(title, message, imageLogo);
		},

		filterVaim(status, title, message, imageLogo) {
			this.filtersClose();
			this.mf.vaim = status;
			this.snack.status = true;
			this.openSnackbar(title, message, imageLogo);
		},

		filterTypeOffice(typeOffice, title, message, imageLogo) {
			this.mf.typeOffice = typeOffice;
			this.typeOfficeStatus = typeOffice;
			this.snack.status = true;
			this.openSnackbar(title, message, imageLogo);
			this.loadAgenciesByType(typeOffice);
		},

		filterGetEventsRgmx(eventsRgmx, title, message, imageLogo) {
			this.mf.eventsRgmx = eventsRgmx;
			this.eventsRgmxStatus = eventsRgmx;
			this.snack.status = true;
			this.openSnackbar(title, message, imageLogo);
			//this.loadEventsByRmgx(eventsRgmx);
		},

		openSnackbar(title, description, imageLogo) {
			this.snack.title = title;
			this.snack.description = description;
			this.snack.imageLogo = imageLogo;
		},

		centerPosition(lat, lon, zoom) {
			this.center = [lat, lon];
			this.zoom = zoom;
		},

		time(word) {
			let w = word.replaceAll(":00.000", "");
			return w;
		},

		cleanWin(winName) {
			let win = winName.replaceAll("Ventanilla de", "");
			return win;
		},

		cleanConsulmex(consulmexName) {
			let name = consulmexName.replaceAll("Consulmex ", "");
			return name;
		},
	},
};
</script>

<style scoped="scss">
.html-block p {
	font-weight: 500 !important;
}
.mgl-map-wrapper,
.mapboxgl-control-container {
	width: 100%;
	height: 60vh;
}
.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
	background-color: transparent;
}
.v-application--is-ltr .v-list-item__icon:first-child {
	margin-right: 16px;
}

.mapboxgl-marker svg::before {
	color: #9f2241;
	content: "\F0352";
	font: normal normal normal 26px/1 "Material Design Icons";
	font-size: 48px;
	line-height: 1;
	text-rendering: auto;
	line-height: inherit;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
/* .mapboxgl-marker svg {
	display: none;
	visibility: hidden;
} */
</style>
