<template>
	<v-container>
		<v-row class="my-8">
			<v-col cols md="6">
				<CollageSlideComponent
					v-for="(slide, i) in collages"
					:key="i"
					:slides="slide.pictures"
					:multiplo="(i + 1) * 200 + 1000"
					class="item-collage float-left" />
			</v-col>

			<v-col cols md="6" class="d-flex justify-center flex-column">
				<h1 class="display-4 text-uppercase font-weight-black">
					{{ title }}
				</h1>
				<h2 class="display-3 text-uppercase font-weight-black">
					{{ subtitle }}
				</h2>
				<h3 class="display-1 text-uppercase font-weight-light mb-8">
					<span v-html="slogan"></span>
					<div class="line-black float-right mt-1"></div>
				</h3>

				<div v-html="content"></div>
			</v-col>
		</v-row>

		<!-- <v-row>
			<v-col>
				<h3 class="text-center blue-grey pa-4 responsive">
					phones, 600px and down
				</h3>
			</v-col>
			<v-col>
				<h3 class="text-center blue-grey pa-4 responsive">
					portrait tablets and large phones, 600px and up
				</h3>
			</v-col>
			<v-col>
				<h3 class="text-center blue-grey pa-4 responsive">
					landscape tablets, 768px and up
				</h3>
			</v-col>
			<v-col>
				<h3 class="text-center blue-grey pa-4 responsive">
					laptops/desktops, 992px and up
				</h3>
			</v-col>
			<v-col>
				<h3 class="text-center blue-grey pa-4 responsive">
					large laptops and desktops, 1200px and up
				</h3>
			</v-col>
			<v-col>
				<h3 class="text-center blue-grey pa-4 responsive">
					extra large laptops and desktops, 1366px and up
				</h3>
			</v-col>

			<v-col>
				<h3 class="text-center blue-grey pa-4 responsive">
					extra large Displays, 1920px and up
				</h3>
			</v-col>
		</v-row> -->
	</v-container>
</template>

<script>
import wsApiHome from "@/services/api-home";
import CollageSlideComponent from "@/components/HomePage/CollageSlide.vue";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";

export default {
	name: "CollageComponent",

	props: {
		title: String,
		subtitle: String,
		slogan: String,
		content: String,
	},

	components: {
		CollageSlideComponent,
		VueperSlides,
		VueperSlide,
	},

	data: () => ({
		collages: {},
		slideCfg: {
			width: "160px",
			height: "160px",
		},
		collage1: {},

		meta: {
			app_name: process.env.VUE_APP_NAME,
			base_url: process.env.VUE_APP_BASE_URL,
			endpoint: process.env.VUE_APP_ENDPOINT,
			storage_files: process.env.VUE_APP_STORAGE_FILES,
		},
	}),

	mounted() {
		this.loadCollage();

		setInterval(() => {
			this.currentImg = this.currentImg + 1;
		}, 3000);
	},

	methods: {
		loadCollage() {
			wsApiHome.getCollage().then(
				(response) => {
					this.collages = response.data.data.image;
				},
				(error) => {
					this.collages =
						(error.response && error.response.data) ||
						error.message ||
						error.toString();
				}
			);
		},

		addUrlToImage(htmlCode) {
			let url = htmlCode.replaceAll("/uploads/", "thumbnail_");
			return url;
		},
	},
};
</script>
