<template>
  <section>
    <MapBoxLayout></MapBoxLayout>

    <!---<v-carousel
      height="88vh"
      :show-arrows="true"
      :continuous="false"
      :cycle="cycle"
      :interval="5000"
      :progress="true"
      progress-color="red"
      hide-delimiter-background
      class="mt-n15"
    >
      <v-carousel-item
        v-for="(item, i) in currentHomePage.slide"
        :key="i"
        :src="meta.storage_files + item.url"
        :lazy-src="meta.storage_files + item.url"
      ></v-carousel-item>
    </v-carousel>-->

    <v-container class="elevation-16 rounded-xl mb-16 mt-12 pa-10">
      <v-row class="pa--5 mb-15">
        <v-col
          cols
          md="6"
          class="pa-5 mb-10"
          v-for="(item, i) in currentHomePage.callAction"
          :key="i"
        >
          <v-img
            :src="meta.storage_files + item.imageCover.url"
            :lazy-src="meta.storage_files + item.imageCover.url"
            class="rounded-xl elevation--5"
            aspect-ratio="1.9"
          />

          <h3 class="display-2 blue-grey--text text--darken-4 my-4">
            {{ item.title }}
          </h3>
          <p class="lead">
            {{ item.description }}
          </p>
          <v-btn
            dark
            color="light-green darken-1"
            class="float-right"
            :to="item.url ? item.url : '#'"
          >
            Más info
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="space my-10">&nbsp;</v-col>
      </v-row>

      <div
        class="projects"
        :style="`
          background-image: url('${
            meta.storage_files + currentHomePage.projectsConfig.background.url
          }');
          background-position: center 65%;
          background-repeat: no-repeat;
          background-size: cover;
        `"
      >
        <v-row dark class="parallax-gradiente rounded-xl pa-6">
          <v-col cols md="12" class="">
            <h2 class="display-2 text-center white--text text-shadow-xl mb-4">
              {{ currentHomePage.projectsConfig.title }}
            </h2>
          </v-col>
          <v-col>
            <carousel
              :autoplay="true"
              :perPageCustom="[
                [320, 1],
                [480, 2],
                [768, 3],
                //[1199, 4],
              ]"
              :centerMode="true"
              :loop="true"
              :speed="currentHomePage.projectsConfig.carouselSpeed"
              :autoplayTimeout="6000"
            >
              <slide
                v-for="(item, i) in projects"
                :key="i"
                :data-index="item.id"
                :data-chapter="item.chapter.slug"
                :data-name="item.slug"
              >
                <div class="ma-4 text-center white--text">
                  <img
                    v-if="item.imageCover"
                    :src="meta.storage_files + item.imageCover.url"
                    :lazy-src="meta.storage_files + item.imageCover.url"
                    class="rounded-xl elevation-5 mx-auto"
                    style="width: 360px; max-width: 100%"
                  />
                  <img
                    v-else
                    :src="`${meta.storage_files}/uploads/RGMX_Proyectos_ce564a2e07.jpg`"
                    lazy-src="/assets/images/back/background5.jpg"
                    class="rounded-xl elevation-5 mx-auto"
                    style="width: 360px; max-width: 100%"
                  />
                  <v-chip
                    color="light-green lighten-1 text-uppercase"
                    class="mt-n9 elevation-5 font-weight-medium"
                    small
                  >
                    {{ item.chapter.title }}
                  </v-chip>
                  <a
                    :href="`/proyectos/${item.slug}`"
                    class="text-decoration-none"
                  >
                    <p class="lead strong white--text text-shadow">
                      {{ item.title }}
                    </p>
                  </a>
                </div>
              </slide>
            </carousel>
            <small class="float-right font-italic white--text">
              <span v-html="currentHomePage.projectsConfig.copyright"></span>
            </small>
          </v-col>
        </v-row>
      </div>

      <v-row>
        <v-col class="space my-10">&nbsp;</v-col>
      </v-row>

      <div
        class="talented-mexicans"
        :style="`
          background-image: url('${
            meta.storage_files +
            currentHomePage.talentedMexicansConfig.background.url
          }');
          background-position: center 55%;
          background-repeat: no-repeat;
          background-size: cover;
        `"
      >
        <v-row dark class="parallax-gradiente rounded-xl pa-6">
          <v-col cols md="12">
            <h2 class="display-2 text-center white--text text-shadow-xl mb-4">
              {{ currentHomePage.talentedMexicansConfig.title }}
            </h2>
          </v-col>
          <v-col
            cols
            md="4"
            v-for="(item, i) in talentedMexicans"
            :key="i"
            class="text-center"
          >
            <v-img
              :src="`https://i.ytimg.com/vi/${item.youtube_id}/hqdefault.jpg`"
              :lazy-src="`https://i.ytimg.com/vi/${item.youtube_id}/hqdefault.jpg`"
              class="rounded-xl elevation-5 mb-2"
              :aspect-ratio="16 / 9"
            />
            <h3 class="text-h5 text-center text-shadow-xl mb-4">
              <a
                :href="`https://www.youtube.com/watch?v=${item.youtube_id}`"
                target="_blank"
                class="white--text text-decoration-none"
              >
                {{ item.title }}
              </a>
            </h3>
          </v-col>
          <v-col cols md="12">
            <small class="float-right font-italic white--text">
              <span
                v-html="currentHomePage.talentedMexicansConfig.copyright"
              ></span>
            </small>
          </v-col>
        </v-row>
      </div>

      <v-row>
        <v-col class="space my-10">&nbsp;</v-col>
      </v-row>

      <v-col cols md="12">
        <h2 class="display-2 text-center blue-grey--text text--darken-4 mb-4">
          Pilares de la RGMX
        </h2>
      </v-col>
      <v-row class="pa--5 mb-15">
        <v-col
          v-for="(item, i) in currentHomePage.pillars"
          :key="i"
          cols
          md="6"
          class="pa-5 mb-10"
        >
          <v-img
            :src="meta.storage_files + item.imageCover.url"
            :lazy-src="meta.storage_files + item.imageCover.url"
            class="rounded-xl elevation-5"
            aspect-ratio="1.9"
          />

          <h3 class="display-1 blue-grey--text text--darken-3 my-4">
            {{ item.title }}
          </h3>
          <v-btn
            dark
            color="light-green darken-1"
            class="float-right"
            :to="item.url ? item.url : '#'"
          >
            Más info
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <pre></pre>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import wsApiRgmx from "@/services/api-rgmx";
import wsApiHome from "@/services/api-home";
import MapBoxLayout from "@/components/layout/MapBox.vue";

export default {
  name: "HomeVersionOneComponent",

  components: {
    MapBoxLayout,
  },

  data: () => ({
    currentHomePage: {
      projectsConfig: {
        background: {},
      },
      talentedMexicansConfig: {
        background: {},
      },
    },
    talentedMexicans: {},
    projects: {},
    cycle: true,
    show: false,
    slide: 0,

    meta: {
      app_name: process.env.VUE_APP_NAME,
      base_url: process.env.VUE_APP_BASE_URL,
      endpoint: process.env.VUE_APP_ENDPOINT,
      storage_files: process.env.VUE_APP_STORAGE_FILES,
    },
  }),

  mounted() {
    //document.title = "Este es mi México | Vinculación y Cultura | IME";
    this.loadHomePage();
    this.loadAllProjects(12);
    this.loadTalentedMexicans();
  },

  methods: {
    loadHomePage() {
      wsApiHome.getHomePageTotal().then(
        (response) => {
          this.currentHomePage = response.data.data;
        },
        (error) => {
          this.currentHomePage =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },

    loadTalentedMexicans() {
      wsApiRgmx.getTalentedMexicans().then(
        (response) => {
          this.talentedMexicans = response.data.data;
        },
        (error) => {
          this.talentedMexicans =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },

    loadAllProjects(paginationLimit) {
      wsApiRgmx.getAllProjectsFavorites(paginationLimit).then(
        (response) => {
          this.projects = response.data.data;
        },
        (error) => {
          this.projects =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },

    handleSlideClick(dataset) {
      //@slideclick="handleSlideClick
      console.log(
        "🚀 ~ file: HelloWorld.vue ~ handleSlideClick ~ dataset:",
        dataset
      );
      //console.log(dataset.index, dataset.name);
    },
  },
};
</script>
