var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('MapBoxLayout'),_c('v-container',{staticClass:"elevation-16 rounded-xl mb-16 mt-12 pa-10"},[_c('v-row',{staticClass:"pa--5 mb-15"},_vm._l((_vm.currentHomePage.callAction),function(item,i){return _c('v-col',{key:i,staticClass:"pa-5 mb-10",attrs:{"cols":"","md":"6"}},[_c('v-img',{staticClass:"rounded-xl elevation--5",attrs:{"src":_vm.meta.storage_files + item.imageCover.url,"lazy-src":_vm.meta.storage_files + item.imageCover.url,"aspect-ratio":"1.9"}}),_c('h3',{staticClass:"display-2 blue-grey--text text--darken-4 my-4"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('p',{staticClass:"lead"},[_vm._v(" "+_vm._s(item.description)+" ")]),_c('v-btn',{staticClass:"float-right",attrs:{"dark":"","color":"light-green darken-1","to":item.url ? item.url : '#'}},[_vm._v(" Más info ")])],1)}),1),_c('v-row',[_c('v-col',{staticClass:"space my-10"},[_vm._v(" ")])],1),_c('div',{staticClass:"projects",style:(`
        background-image: url('${
          _vm.meta.storage_files + _vm.currentHomePage.projectsConfig.background.url
        }');
        background-position: center 65%;
        background-repeat: no-repeat;
        background-size: cover;
      `)},[_c('v-row',{staticClass:"parallax-gradiente rounded-xl pa-6",attrs:{"dark":""}},[_c('v-col',{attrs:{"cols":"","md":"12"}},[_c('h2',{staticClass:"display-2 text-center white--text text-shadow-xl mb-4"},[_vm._v(" "+_vm._s(_vm.currentHomePage.projectsConfig.title)+" ")])]),_c('v-col',[_c('carousel',{attrs:{"autoplay":true,"perPageCustom":[
              [320, 1],
              [480, 2],
              [768, 3],
              //[1199, 4],
            ],"centerMode":true,"loop":true,"speed":_vm.currentHomePage.projectsConfig.carouselSpeed,"autoplayTimeout":6000}},_vm._l((_vm.projects),function(item,i){return _c('slide',{key:i,attrs:{"data-index":item.id,"data-chapter":item.chapter.slug,"data-name":item.slug}},[_c('div',{staticClass:"ma-4 text-center white--text"},[(item.imageCover)?_c('img',{staticClass:"rounded-xl elevation-5 mx-auto",staticStyle:{"width":"360px","max-width":"100%"},attrs:{"src":_vm.meta.storage_files + item.imageCover.url,"lazy-src":_vm.meta.storage_files + item.imageCover.url}}):_c('img',{staticClass:"rounded-xl elevation-5 mx-auto",staticStyle:{"width":"360px","max-width":"100%"},attrs:{"src":`${_vm.meta.storage_files}/uploads/RGMX_Proyectos_ce564a2e07.jpg`,"lazy-src":"/assets/images/back/background5.jpg"}}),_c('v-chip',{staticClass:"mt-n9 elevation-5 font-weight-medium",attrs:{"color":"light-green lighten-1 text-uppercase","small":""}},[_vm._v(" "+_vm._s(item.chapter.title)+" ")]),_c('a',{staticClass:"text-decoration-none",attrs:{"href":`/proyectos/${item.slug}`}},[_c('p',{staticClass:"lead strong white--text text-shadow"},[_vm._v(" "+_vm._s(item.title)+" ")])])],1)])}),1),_c('small',{staticClass:"float-right font-italic white--text"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.currentHomePage.projectsConfig.copyright)}})])],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"space my-10"},[_vm._v(" ")])],1),_c('div',{staticClass:"talented-mexicans",style:(`
        background-image: url('${
          _vm.meta.storage_files +
          _vm.currentHomePage.talentedMexicansConfig.background.url
        }');
        background-position: center 55%;
        background-repeat: no-repeat;
        background-size: cover;
      `)},[_c('v-row',{staticClass:"parallax-gradiente rounded-xl pa-6",attrs:{"dark":""}},[_c('v-col',{attrs:{"cols":"","md":"12"}},[_c('h2',{staticClass:"display-2 text-center white--text text-shadow-xl mb-4"},[_vm._v(" "+_vm._s(_vm.currentHomePage.talentedMexicansConfig.title)+" ")])]),_vm._l((_vm.talentedMexicans),function(item,i){return _c('v-col',{key:i,staticClass:"text-center",attrs:{"cols":"","md":"4"}},[_c('v-img',{staticClass:"rounded-xl elevation-5 mb-2",attrs:{"src":`https://i.ytimg.com/vi/${item.youtube_id}/hqdefault.jpg`,"lazy-src":`https://i.ytimg.com/vi/${item.youtube_id}/hqdefault.jpg`,"aspect-ratio":16 / 9}}),_c('h3',{staticClass:"text-h5 text-center text-shadow-xl mb-4"},[_c('a',{staticClass:"white--text text-decoration-none",attrs:{"href":`https://www.youtube.com/watch?v=${item.youtube_id}`,"target":"_blank"}},[_vm._v(" "+_vm._s(item.title)+" ")])])],1)}),_c('v-col',{attrs:{"cols":"","md":"12"}},[_c('small',{staticClass:"float-right font-italic white--text"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.currentHomePage.talentedMexicansConfig.copyright)}})])])],2)],1),_c('v-row',[_c('v-col',{staticClass:"space my-10"},[_vm._v(" ")])],1),_c('v-col',{attrs:{"cols":"","md":"12"}},[_c('h2',{staticClass:"display-2 text-center blue-grey--text text--darken-4 mb-4"},[_vm._v(" Pilares de la RGMX ")])]),_c('v-row',{staticClass:"pa--5 mb-15"},_vm._l((_vm.currentHomePage.pillars),function(item,i){return _c('v-col',{key:i,staticClass:"pa-5 mb-10",attrs:{"cols":"","md":"6"}},[_c('v-img',{staticClass:"rounded-xl elevation-5",attrs:{"src":_vm.meta.storage_files + item.imageCover.url,"lazy-src":_vm.meta.storage_files + item.imageCover.url,"aspect-ratio":"1.9"}}),_c('h3',{staticClass:"display-1 blue-grey--text text--darken-3 my-4"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('v-btn',{staticClass:"float-right",attrs:{"dark":"","color":"light-green darken-1","to":item.url ? item.url : '#'}},[_vm._v(" Más info ")])],1)}),1),_c('v-row',[_c('pre')])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }