<template>
  <section class="my-16">
    <v-row>
      <v-col
        class="py-16 over-grey"
        :style="`background-image: url(${meta.storage_files}/uploads/rgmx14_99f02d95da.jpg);background-size: cover;background-position: center top;`"
      >
        <h2
          class="black--text display-2 text-center font-weight--medium text-uppercase my-6"
        >
          ¡<strong>Localiza un Nodo <br />o Capítulo</strong> y súmate!
        </h2>
      </v-col>
    </v-row>

    <v-row
      dark
      class="rounded-xl elevation-10 py-6 px-14 mb-15"
      style="
        background-color: #1c1d1f;
        background-image: url('/assets/images/back/estrellas.png');
        background-repeat: repeat;
      "
    >
      <v-col cols md="12" class="pa-5">
        <v-row>
          <v-col cols md="6" class="py-8 gradient-pink d-flex align-center">
            <div class="iconography mt-6">
              <ul class="no-list">
                <li class="float--left mr-5 white--text display-1">
                  <v-icon large color="#F50057">mdi mdi-map-marker</v-icon>
                  Nodos MX
                </li>
                <li class="pl-11">
                  <h3 class="display-1 strong mt-6 mb-3 white--text">
                    Capítulos
                  </h3>
                </li>
                <li
                  v-for="(item, i) in getAllRegions"
                  :key="i"
                  class="float--left mr-5 white--text display-1 mb-2"
                >
                  <v-icon large :color="item.colorPin"
                    >mdi mdi-map-marker</v-icon
                  >
                  {{ item.name }}
                </li>
              </ul>
            </div>
          </v-col>

          <v-col cols md="6" class="d-flex align-center">
            <MglMap
              :accessToken="accessToken"
              :mapStyle.sync="mapStyle"
              :zoom="zoom"
              :center="center"
              :attributionControl="true"
            >
              <MglNavigationControl position="top-right" />
              <MglGeolocateControl position="top-right" />
              <MglFullscreenControl></MglFullscreenControl>

              <MglMarker
                v-for="(item, i) in getGeoNode"
                :key="`node-${i}`"
                :coordinates="[
                  item.geolocation ? item.geolocation.longitud : i,
                  item.geolocation ? item.geolocation.latitud : i,
                ]"
                color="#D81B60"
              >
                <MglPopup anchor="top">
                  <VCard class="elevation-0 mapbox-card">
                    <v-card-title class="title">{{ item.title }}</v-card-title>
                    <v-card-actions class="mt-0">
                      <v-btn
                        x-small
                        dark
                        color="rojo"
                        class="float-right"
                        :href="`/nodo/${item.slug}`"
                      >
                        mas info
                      </v-btn>
                      <v-btn
                        v-if="item.Contacto.email"
                        x-small
                        dark
                        color="verde"
                        class=""
                        :href="`mailto:${item.Contacto.email}`"
                      >
                        contactanos
                      </v-btn>
                    </v-card-actions>
                    <v-card-text v-if="item.summary" class="body-2">
                      {{ item.summary }}
                    </v-card-text>
                  </VCard>
                </MglPopup>
              </MglMarker>

              <MglMarker
                v-for="(item, i) in getGeoChapter"
                :key="`chapter-${i}`"
                :coordinates="[
                  item.geolocation ? item.geolocation.longitud : i,
                  item.geolocation ? item.geolocation.latitud : i,
                ]"
                :color="item.region.colorPin"
                class="animate__animated animate__bounce animate__delay-2s"
              >
                <MglPopup anchor="top">
                  <VCard class="elevation-0 mapbox-card">
                    <v-card-title class="title">{{ item.title }}</v-card-title>
                    <v-card-actions class="">
                      <v-btn
                        x-small
                        dark
                        color="rojo"
                        class=""
                        :href="`/capitulos/${item.slug}`"
                      >
                        mas info
                      </v-btn>
                      <v-btn
                        v-if="item.contacto.email"
                        x-small
                        dark
                        color="verde"
                        class=""
                        :href="`mailto:${item.contacto.email}`"
                      >
                        contactanos
                      </v-btn>
                    </v-card-actions>
                    <v-card-text v-if="item.summary" class="body-2">
                      {{ item.summary }}
                    </v-card-text>
                  </VCard>
                </MglPopup>
              </MglMarker>
            </MglMap>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <pre></pre>
    </v-row>
  </section>
</template>

<script>
import wsApiRgmx from "@/services/api-rgmx";
import wsApiHome from "@/services/api-home";
import Mapbox from "mapbox-gl";
import {
  MglMap,
  MglAttributionControl,
  MglNavigationControl,
  MglGeolocateControl,
  MglFullscreenControl,
  MglScaleControl,
  MglPopup,
  MglMarker,
} from "vue-mapbox";

export default {
  name: "MapBoxLayout",

  components: {
    MglMap,
    MglAttributionControl,
    MglNavigationControl,
    MglGeolocateControl,
    MglFullscreenControl,
    MglScaleControl,
    MglPopup,
    MglMarker,
  },

  data: () => ({
    getGeoNode: {},
    getGeoChapter: {},
    getAllRegions: {},
    homeMap: {},
    accessToken: process.env.VUE_APP_MAPBOX,
    //mapStyle: "mapbox://styles/mapbox/navigation-night-v1",
    mapStyle: "mapbox://styles/mapbox/outdoors-v12",
    center: [-98.7979775, 20.0825146],
    zoom: 1.5,

    meta: {
      app_name: process.env.VUE_APP_NAME,
      base_url: process.env.VUE_APP_BASE_URL,
      endpoint: process.env.VUE_APP_ENDPOINT,
      storage_files: process.env.VUE_APP_STORAGE_FILES,
    },
  }),

  mounted() {
    //document.title = `Red Global MX | ${this.meta.app_name}`;
    this.loadHomePage();
    this.loadNodesLatLong();
    this.loadChaptersLatLong();
    this.loadAllRegions();
  },

  created() {
    // We need to set mapbox-gl library here in order to use it in template
    this.mapbox = Mapbox;
  },

  methods: {
    loadHomePage() {
      wsApiHome.getHomeMap().then(
        (response) => {
          this.homeMap = response.data.data.home_map;
        },
        (error) => {
          this.homeMap =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },

    loadNodesLatLong() {
      wsApiRgmx.getNodesMap().then(
        (response) => {
          this.getGeoNode = response.data.data;
        },
        (error) => {
          this.getGeoNode =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },

    loadChaptersLatLong() {
      wsApiRgmx.getChaptersMap().then(
        (response) => {
          this.getGeoChapter = response.data.data;
        },
        (error) => {
          this.getGeoChapter =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },

    loadAllRegions() {
      wsApiRgmx.getAllRegions().then(
        (response) => {
          this.getAllRegions = response.data.data;
        },
        (error) => {
          this.getAllRegions =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },

    v(word) {
      let w = word.replaceAll("Región", "");
      return w;
    },
  },
};
</script>

<style>
.mgl-map-wrapper,
.mapboxgl-control-container {
  height: 550px !important;
}
.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
  background-color: transparent;
}
/*
.mapboxgl-ctrl.mapboxgl-ctrl-group button {
  color: #1c1d1f;
}
.mapboxgl-ctrl.mapboxgl-ctrl-group button span {
  color: #1c1d1f !important;
}
*/
</style>
