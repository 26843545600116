<template>
	<section>
		<v-menu offset-y class="">
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					tile
					color="amber darken-3"
					v-bind="attrs"
					v-on="on"
					class="ma-1 text-capitalize elevation-0">
					RGMX
					<v-icon right class="mdi mdi-arrow-down-drop-circle-outline"></v-icon>
				</v-btn>
			</template>
			<v-list dense class="pa---2 mt---4">
				<v-list-item
					v-for="item in getMenuRgmx"
					:key="item.id"
					link
					dark
					:href="`/${item.section}/${item.slug}`"
					class="my-1">
					<v-list-item-icon class="mr-0">
						<v-icon left small class="blue-grey--text text--darken-4"
							>mdi mdi-sitemap-outline</v-icon
						>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title
							class="blue-grey--text text--darken-4 font-weight-regular font-poppins"
							>{{ item.title }}</v-list-item-title
						>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</v-menu>

		<v-menu offset-y>
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					tile
					color="light-green darken-2"
					v-bind="attrs"
					v-on="on"
					class="ma-1 text-capitalize elevation-0">
					Capítulos
					<v-icon right class="mdi mdi-arrow-down-drop-circle-outline"></v-icon>
				</v-btn>
			</template>
			<v-list dense class="pa---2 mt---4">
				<v-list-item link dark href="/capitulo/que-es-un-capitulo" class="my-1">
					<v-list-item-icon class="mr-0">
						<v-icon left small class="blue-grey--text text--darken-4"
							>mdi mdi-help-circle-outline</v-icon
						>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title
							class="blue-grey--text text--darken-4 font-weight-regular font-poppins"
							>¿Qué es un Capítulo?</v-list-item-title
						>
					</v-list-item-content>
				</v-list-item>

				<v-list-item
					v-for="item in getRegions"
					:key="item.title"
					link
					dark
					:href="`/regiones/${item.title}`"
					class="my-1">
					<v-list-item-icon class="mr-0">
						<v-icon left small class="blue-grey--text text--darken-4"
							>mdi mdi-chart-bubble</v-icon
						>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title
							class="blue-grey--text text--darken-4 font-weight-regular font-poppins">
							Región {{ item.name }}
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</v-menu>

		<v-menu offset-y>
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					tile
					color="pink lighten-1"
					v-bind="attrs"
					v-on="on"
					class="ma-1 text-capitalize elevation-0">
					Nodos
					<v-icon right class="mdi mdi-arrow-down-drop-circle-outline"></v-icon>
				</v-btn>
			</template>
			<v-list dense class="pa---2 mt---4">
				<v-list-item
					v-for="item in nodes"
					:key="item.title"
					link
					dark
					:href="`${item.url}`"
					class="my-1">
					<v-list-item-icon class="mr-0">
						<v-icon left small class="blue-grey--text text--darken-4">{{
							item.icon
						}}</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title
							class="blue-grey--text text--darken-4 font-weight-regular font-poppins"
							>{{ item.title }}</v-list-item-title
						>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</v-menu>
	</section>
</template>

<script>
import wsApiRgmx from "@/services/api-rgmx";

export default {
	name: "NavigationTopTemplate",

	components: {},

	data: () => ({
		drawer: null,
		getRegions: {},
		getMenuRgmx: {},

		nodes: [
			{
				title: "¿Qué es un Nodo?",
				url: "/nodos/que-es-un-nodo",
				icon: "mdi mdi-help-circle-outline",
			},
			{
				title: "Nodos",
				url: "/nodos/",
				icon: "mdi mdi-chart-bubble",
			},
		],

		meta: {
			app_name: process.env.VUE_APP_NAME,
			base_url: process.env.VUE_APP_BASE_URL,
			endpoint: process.env.VUE_APP_ENDPOINT,
			storage_files: process.env.VUE_APP_STORAGE_FILES,
		},
	}),

	mounted() {
		//document.title = `Red Global MX | ${this.meta.app_name}`;
		this.loadRegions();
		this.loadMenuRgmx("rgmx");
	},

	methods: {
		loadRegions() {
			wsApiRgmx.getAllRegions().then(
				(response) => {
					this.getRegions = response.data.data;
				},
				(error) => {
					this.getRegions =
						(error.response && error.response.data) ||
						error.message ||
						error.toString();
				}
			);
		},

		loadMenuRgmx(section) {
			wsApiRgmx.getPagesBySection(section).then(
				(response) => {
					this.getMenuRgmx = response.data.data;
				},
				(error) => {
					this.getMenuRgmx =
						(error.response && error.response.data) ||
						error.message ||
						error.toString();
				}
			);
		},
	},
};
</script>
