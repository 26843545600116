<template>
	<div class="pink- mb-16 py--8">
		<v-container>
			<v-row dark class="pa-6">
				<!-- parallax-gradiente rounded-xl -->
				<v-col cols md="12" class="">
					<h2 class="display-2 text-uppercase text-center mb-4">
						Programas <strong>Institucionales</strong>
					</h2>
				</v-col>
				<v-col>
					<carousel
						cycle
						:autoplay="true"
						:perPageCustom="[
							[320, 1],
							[480, 2],
							[768, 3],
							[1199, 4],
						]"
						:centerMode="true"
						:loop="true"
						:speed="800"
						:autoplayTimeout="3000"
						:interval="2000">
						<slide v-for="(item, i) in items" :key="i" :data-index="i">
							<div class="ma-4 text-center white--text">
								<a :href="item.url" target="_blank">
									<img
										:src="meta.storage_files + item.src"
										:lazy-src="item.src"
										class="rounded-x elevation-5 mx-auto mt-2 rounded-xl"
										style="width: 320px; max-width: 100%"
								/></a>
							</div>
						</slide>
					</carousel>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
import wsApiHome from "@/services/api-home";

export default {
	name: "ProgramsComponent",

	components: {},

	data: () => ({
		message: "Click for slide",
		show: true,
		items: [
			{
				src: "/uploads/ciesdemex_9ef7a62485.jpg",
				url: "https://ciesdemex.org/",
			},
			{
				src: "/uploads/sep_217970d566.jpg",
				url: "https://www.gob.mx/sep",
			},
			{
				src: "/uploads/anuies_a9914e987a.jpg",
				url: "http://www.anuies.mx/",
			},
			{
				src: "/uploads/amexcid_a749e2962d.jpg",
				url: "https://www.gob.mx/amexcid",
			},
			{
				src: "/uploads/ciesdemex_9ef7a62485.jpg",
				url: "https://ciesdemex.org/",
			},
			{
				src: "/uploads/sep_217970d566.jpg",
				url: "https://www.gob.mx/sep",
			},
			{
				src: "/uploads/anuies_a9914e987a.jpg",
				url: "http://www.anuies.mx/",
			},
			{
				src: "/uploads/amexcid_a749e2962d.jpg",
				url: "https://www.gob.mx/amexcid",
			},
		],

		meta: {
			app_name: process.env.VUE_APP_NAME,
			base_url: process.env.VUE_APP_BASE_URL,
			endpoint: process.env.VUE_APP_ENDPOINT,
			storage_files: process.env.VUE_APP_STORAGE_FILES,
		},
	}),

	created() {},

	mounted() {
		//this.loadHomePage();
	},

	methods: {
		loadHomePage() {
			wsApiHome.getHomePageTotal().then(
				(response) => {
					this.currentHomePage = response.data.data;
					this.getNode = this.currentHomePage.callAction[0];
					this.getChapter = this.currentHomePage.callAction[1];
				},
				(error) => {
					this.currentHomePage =
						(error.response && error.response.data) ||
						error.message ||
						error.toString();
				}
			);
		},

		addUrlToImage(htmlCode) {
			let url = htmlCode.replaceAll("/uploads/", "thumbnail_");
			return url;
		},
	},
};
</script>
