<template>
	<v-row>
		<v-col cols md="12">
			<h2 class="text-h5 font-weight-bold text-center mt-2 mb-4">
				<!-- <v-icon>mdi-{{ geoData.agency_type.icon }}</v-icon> -->
				{{ geoData.agency_type.name }}
			</h2>
			<v-row class="mb-1 mx-1">
				<v-simple-table class="my-4">
					<template v-slot:default>
						<tbody>
							<tr>
								<td>
									<small class="text-uppercase blue-grey--text">
										Horario de atención
									</small>
									<p class="font-weight-medium">
										{{ geoData.hourly }}
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<small class="text-uppercase blue-grey--text">
										Servicios
									</small>
									<div
										class="html-block font-weight-regular"
										v-html="geoData.services"></div>
								</td>
							</tr>
							<tr v-if="geoData.allies">
								<td>
									<small class="text-uppercase blue-grey--text">
										Organización o Institución Aliada
									</small>
									<p class="font-weight-medium text-capitalize">
										{{ geoData.allies.toLowerCase() }}
									</p>
								</td>
							</tr>

							<tr class="blue-grey darken-2">
								<td class="white--text text-center text-uppercase strong">
									Operador de la ventanilla
								</td>
							</tr>
							<tr>
								<td>
									<small class="text-uppercase blue-grey--text">
										Operador
									</small>
									<p class="font-weight-medium">
										{{ geoData.operatorName }}
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<small class="text-uppercase blue-grey--text">
										Teléfono/Ext
									</small>
									<p class="font-weight-medium">
										{{ geoData.operatorPhone }}
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<small class="text-uppercase blue-grey--text">
										Correo electrónico
									</small>
									<p class="font-weight-medium">
										{{ geoData.operatorEmail }}
									</p>
								</td>
							</tr>

							<tr v-if="geoData.maex" class="blue-grey darken-2">
								<td class="white--text text-center text-uppercase strong">
									MAEX
								</td>
							</tr>
							<tr v-if="geoData.maex">
								<td>
									<small class="text-uppercase blue-grey--text">
										Horario de Atención
									</small>
									<p class="font-weight-medium">
										{{ geoData.maexHourly }}
									</p>
								</td>
							</tr>
							<tr v-if="geoData.maex">
								<td>
									<small class="text-uppercase blue-grey--text">
										Correo electrónico
									</small>
									<p class="font-weight-medium">
										{{ geoData.maexEmail }}
									</p>
								</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>
			</v-row>
		</v-col>
	</v-row>
</template>

<script>
export default {
	name: "AgencyMap",

	props: {
		geoData: Object,
	},

	components: {},

	data: () => ({
		dialog: false,
		dialogComunidades: false,

		meta: {
			app_name: process.env.VUE_APP_NAME,
			app_version: process.env.VUE_APP_VERSION,
			base_url: process.env.VUE_APP_BASE_URL,
			endpoint: process.env.VUE_APP_ENDPOINT,
			storage_files: process.env.VUE_APP_STORAGE_FILES,
		},
	}),

	computed: {},

	mounted() {},

	methods: {
		moreInformation(rmeName, agency) {
			this.dialog = true;
			this.rme_name = rmeName;
			this.agency_type = agency;
		},

		consulInformation(rmeName, comunidades) {
			this.dialogComunidades = true;
			this.rme_name = rmeName;
			this.data_consul = comunidades;
		},

		cleanWin(winName) {
			let win = winName.replaceAll("Ventanilla de", "");
			return win;
		},
	},
};
</script>
