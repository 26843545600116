<template>
	<v-row>
		<v-col cols md="12">
			<h2 class="text-h5 font-weight-bold text-center mt-2 mb-4">
				{{ geoData.name }}
			</h2>

			<v-row class="mb-1 mx-1">
				<v-col cols md="6" class="py-2">
					<v-btn
						dark
						block
						color="verde"
						@click="consulInformation(geoData.name, geoData.community_consul)"
						class="text-capitalize btn-lowercase">
						<v-icon left class="">mdi-hand-heart</v-icon>
						Comunidades
					</v-btn>
				</v-col>

				<v-col
					v-for="(agency, i) in geoData.agencies"
					:key="i"
					cols
					md="6"
					class="py-2"
					:class="`${agency.agency_type.slug}`">
					<v-btn
						dark
						block
						:color="agency.agency_type.bgColor"
						@click="moreInformation(geoData.name, agency)"
						class="text-capitalize btn-lowercase"
						v-if="agency.agency_type.id != 6 || agency.agency_type.id != 7">
						<v-icon left>mdi-{{ agency.agency_type.icon }}</v-icon>
						{{
							cleanWin(
								agency.agency_type.id === 2
									? agency.agency_type.shortName
									: agency.agency_type.name
							)
						}}
					</v-btn>
				</v-col>
			</v-row>
			<v-row v-if="geoData.address" class="mx-1">
				<v-col cols md="12">
					<small class="text-uppercase">Dirección</small>
					<p class="font-weight-bold">
						<a
							:href="`https://www.google.es/maps/place/${geoData.address}`"
							class="text-decoration-none"
							target="_blank">
							{{ geoData.address }}
						</a>
					</p>
				</v-col>
			</v-row>
		</v-col>

		<!-- Popup Ventanillas-->
		<v-col cols="auto">
			<v-dialog scrollable v-model="dialog" max-width="500">
				<v-card max-height="600">
					<v-card-title class="text-h6 strong blue-grey darken-4 white--text">
						<v-btn fab small class="mr-3">
							<v-icon> mdi-{{ data_agency.agency_type.icon }} </v-icon>
						</v-btn>

						{{ data_agency.agency_type.shortName }}
						<v-icon class="blue-grey--text text--lighten-2 mx-1">
							mdi-chevron-double-right
						</v-icon>
						{{ rme_name }}
					</v-card-title>

					<v-card-text>
						<RmePopupTable :dataAgency="data_agency" />
					</v-card-text>

					<v-divider></v-divider>

					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							fab
							dark
							x-small
							color="red darken-2"
							@click="dialog = false">
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-col>

		<!-- popup Asuntos comunitarios -->
		<v-col cols="auto">
			<v-dialog scrollable v-model="dialogComunidades" max-width="500">
				<v-card max-height="600">
					<v-card-title class="text-h6 strong blue-grey darken-4 white--text">
						<v-btn fab small class="mr-3">
							<v-icon> mdi-hand-heart </v-icon>
						</v-btn>
						Comunidades
						<v-icon class="blue-grey--text text--lighten-2 mx-1">
							mdi-chevron-double-right
						</v-icon>
						{{ rme_name }}
					</v-card-title>

					<v-card-text>
						<RmePopupTableCommunities :dataConsul="data_consul" />
					</v-card-text>

					<v-divider></v-divider>

					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							fab
							dark
							x-small
							color="red darken-2"
							@click="dialogComunidades = false">
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-col>
	</v-row>
</template>

<script>
import RmePopupTable from "./rme_popup_table.vue";
import RmePopupTableCommunities from "./rme_popup_table_communities";
export default {
	name: "RmeMap",

	props: {
		geoData: Object,
	},

	components: {
		RmePopupTable,
		RmePopupTableCommunities,
	},

	data: () => ({
		dialog: false,
		dialogComunidades: false,
		rme_name: "",
		data_agency: {
			agency_type: {},
		},
		data_consul: {},

		meta: {
			app_name: process.env.VUE_APP_NAME,
			app_version: process.env.VUE_APP_VERSION,
			base_url: process.env.VUE_APP_BASE_URL,
			endpoint: process.env.VUE_APP_ENDPOINT,
			storage_files: process.env.VUE_APP_STORAGE_FILES,
		},
	}),

	computed: {},

	mounted() {},

	methods: {
		moreInformation(rmeName, agency) {
			this.dialog = true;
			this.rme_name = rmeName;
			this.data_agency = agency;
		},

		consulInformation(rmeName, comunidades) {
			this.dialogComunidades = true;
			this.rme_name = rmeName;
			this.data_consul = comunidades;
		},

		cleanWin(winName) {
			let win = winName.replaceAll("Ventanilla de", "");
			return win;
		},
	},
};
</script>
